import React from "react";
import {
    Card,
    Form,
    Row,
    Col,
    Input,
    Button,
    message,
    Upload,
    DatePicker,
    Select,
    Tooltip,
    Spin
} from "antd";
import moment from "moment";
import { QuestionCircleOutlined, UploadOutlined } from '@ant-design/icons';

import {
    AUTH,
    CONTRACT_HEAD_TYPE_ALL,
    CONTRACT_SUB_TYPE_OBJ,
    ROLES,
    CONTRACT_STANDARD
} from "../../../../../utils/constant";
import { crmApi } from "../../../../../api";
import storage from "../../../../../utils/storage";
import NumberInput from "../../../../crm/components/number-input";
import memory from "../../../../../utils/memory";
import DownloadLink from "../../../../../components/download-link";
import Remarks from "../../../../../components/remarks";
import tools from "../../../../../utils/tools";

const {
    Option
} = Select;
const { TextArea } = Input;
const { RangePicker } = DatePicker;
const {
    reqApplyDetail,
    reqContractApplyEdit,
} = crmApi;
const {
    PROJECT_CONSTRACT
} = ROLES

class PendingContractEdit extends React.Component {
    state = {
        detail: {},
        fileListSingle: [],
        fileListDouble: [],
        contract_type: '',
        submitLoading: false,
        receiving_mode: -1,
        is_history: false,
        fileLoading: false,
        loading: this.props.match.params.id ? true : false
    };
    bid = this.props.match.params.bid; //项目id
    id = this.props.match.params.id; //合同id
    cid = ''; //客户id
    CONTRACT_SUB_TYPE_ARR = memory.user.roles.find(o => o.type == PROJECT_CONSTRACT) ? [3, 2, 1, 4] : [3, 2, 1];

    handleTypeChange = value => {
        this.getContractID({ contract_type: value, contract_stype: this.props.form.getFieldValue('contract_stype') })
        this.setState({
            contract_type: value
        });
    };

    /* 删除已上传文件 */
    handleRemoveFile = () => {
        this.setState({
            fileLoading: false,
        })
    }

    /*上传相关*/
    handleChangeSingle = info => {
        let fileList = [...info.fileList];
        if (info.file.status === 'uploading') {
            this.setState({
                fileLoading: true,
            })
        }
        if (info.file.status === 'done') {
            this.setState({
                fileLoading: false,
            })
        }
        // 2. Read from response and show file link
        fileList = fileList.map(file => {
            if (file.response) {
                // Component will show file.url as link
                file.url = file.response.url;
            }
            file = {
                uid: file.uid,
                name: file.name,
                status: file.status,
                url: file.url
            };
            return file;
        });

        this.setState({ fileListSingle: fileList });
    };

    handlePreview = async (file) => {
        tools.downloadFile(this.props.history, file, `${memory.user.dd_info.name} ${moment().format("YYYY/MM/DD")}`);
    }

    handleChangeDouble = info => {
        let fileList = [...info.fileList];
        if (info.file.status === 'uploading') {
            this.setState({
                fileLoading: true,
            })
        }
        if (info.file.status === 'done') {
            this.setState({
                fileLoading: false,
            })
        }
        // 2. Read from response and show file link
        fileList = fileList.map(file => {
            if (file.response) {
                // Component will show file.url as link
                file.url = file.response.url;
            }
            file = {
                uid: file.uid,
                name: file.name,
                status: file.status,
                url: file.url
            };
            return file;
        });

        this.setState({ fileListDouble: fileList });
    };

    validate = async (values) => {
        if (this.state.fileLoading) {
            message.error('提交失败，请先等待合同文件上传完毕');
            return;
        }
        this.setState({ submitLoading: true })
        const { fileListSingle, fileListDouble } = this.state;

        let response;
        values.sign_date = new Date(values.sign_date._d); //签署日期
        values.contract_startdate = new Date(values.contract_time[0]._d); //开始时间
        values.contract_enddate = new Date(values.contract_time[1]._d); //结束时间
        delete values.contract_time;
        values.contract_file = [];
        if (fileListSingle.length || fileListDouble.length) {
            values.moa_contract_file_single = fileListSingle;
            values.moa_contract_file_double = fileListDouble;
        } else {
            message.warning('请上传合同扫描文件');
            this.setState({ submitLoading: false })
            return;
        }
        if (values.paper_contract_number == null) delete values['paper_contract_number'];
        values.aid = this.id;
        
        if (this.state.receiving_mode) {
            //1对应选项参数的1，3对应选项参数的-1 1自取2快递3无
            values.receiving_mode =this.state.receiving_mode === 1?1:3;
            delete values['express_number']
        } else {
            //2对应选项参数的0
            values.receiving_mode = 2;
        }
        delete values['contract_type']
        delete values['contract_stype']
        delete values['contract_number']
        if (values.remarks) {
            values.remarks = values.remarks.toString();
        }
        response = await reqContractApplyEdit(values);
        if (response && response.data) {
            if (this.id) {
                message.success('修改成功');
            } else {
                message.success('申请成功');
            }
            this.props.history.push('/moa/contract/pending/list');
        } else {
            this.setState({ submitLoading: false })
        }
    }

    getContractDetail = async (params = {}) => {
        const response = await reqApplyDetail(params);
        if (response && response.data) {
            this.setState({
                detail: response.data.data,
                fileListSingle: response.data.data.moa_contract_file_single || [],
                fileListDouble: response.data.data.moa_contract_file_double || [],
                contract_type: response.data.data.contract_type,
                receiving_mode: response.data.data.mode && response.data.data.mode.receiving_mode ? (response.data.data.mode.receiving_mode === 1 ? 1 : 0) : -1,
                is_history: response.data.is_history,
                loading: false
            });
        }
    };

    componentDidMount() {
        if (this.id) {
            // 获取合同详情
            this.getContractDetail({
                id: this.id
            });
        }
    }

    render() {
        const { detail, loading } = this.state;

        let headers = {};
        const auth = storage.getStore(AUTH);
        if (auth) {
            headers = {
                authorization: 'Bearer ' + auth
            };
        }

        const propsSingle = {
            action: '/api/files/ue?action=uploadfile',
            headers: headers,
            onChange: this.handleChangeSingle,
            onRemove: this.handleRemoveFile,
            multiple: true,
            accept: '.pdf',
            onPreview: this.handlePreview,
        };
        const propsDouble = {
            action: '/api/files/ue?action=uploadfile',
            headers: headers,
            onChange: this.handleChangeDouble,
            onRemove: this.handleRemoveFile,
            multiple: true,
            accept: '.pdf',
            onPreview: this.handlePreview,
        };

        return loading ? (
            <Spin />
        ) : (
            <Form onFinish={this.validate} layout="vertical">
                <Card
                    title={
                        this.id ? "修改合同信息" : "合同申请"
                    }
                    bordered={false}
                >
                    <Card
                        bordered={false}
                        type="inner"
                    >
                        <Row gutter={{ sm: 0, md: 16, lg: 24 }}>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="合同名称"
                                    name="contract_name"
                                    initialValue={detail.contract_name || ''}
                                    rules={[
                                        {
                                            required: true,
                                            message: '请输入合同名称',
                                        },
                                    ]}
                                >
                                    <Input placeholder="请输入合同名称" disabled />
                                </Form.Item>
                            </Col>
                            {
                                detail.specifications ?
                                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                        <Form.Item
                                            label={
                                                <span>是否是标准合同<Remarks title='标准合同审批速度更快' /></span>
                                            }
                                            name="specifications"
                                            initialValue={detail.specifications || undefined}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '请选择是否是标准合同',
                                                },
                                            ]}
                                        >
                                            <Select placeholder="请选择是否是标准合同" disabled  >
                                                {CONTRACT_STANDARD.map((item, index) => (
                                                    <Option key={index} value={item.key}>
                                                        {item.text}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    :
                                    null
                            }
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="合同类型"
                                    name="contract_type"
                                    initialValue={detail.contract_type !== undefined ? detail.contract_type : null}
                                    rules={[
                                        {
                                            required: true,
                                            message: '请选择合同类型',
                                        },
                                    ]}
                                >
                                    <Select placeholder="请选择合同类型" disabled>
                                        {CONTRACT_HEAD_TYPE_ALL.map((item, index) => (
                                            <Option key={index} value={item.key}>
                                                {item.text}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="合同性质"
                                    name="contract_stype"
                                    initialValue={detail.contract_stype !== undefined ? detail.contract_stype : null}
                                    rules={[
                                        {
                                            required: true,
                                            message: '请选择合同性质',
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="请选择合同性质"
                                        disabled
                                    >
                                        {this.CONTRACT_SUB_TYPE_ARR.map((item, index) => (
                                            <Option key={item} value={item}>
                                                {CONTRACT_SUB_TYPE_OBJ[item].text}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label=
                                    {
                                        <span>合同编号
                                            <Tooltip title="根据合同类型自动生成">
                                                <QuestionCircleOutlined style={{ fontSize: 13.5, color: 'gray' }} />
                                            </Tooltip>
                                        </span>
                                    }
                                    name="contract_number"
                                    initialValue={detail.contract_number || ''}
                                    rules={[
                                        {
                                            required: true,
                                            message: '请输入合同编号',
                                        },
                                    ]}
                                >
                                    <Input placeholder="请完善合同类型" disabled />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="合同签署日期"
                                    name="sign_date"
                                    initialValue={detail.sign_date ? moment(detail.sign_date) : null}
                                    rules={[
                                        {
                                            required: true,
                                            message: '请选择合同签署日期',
                                        },
                                    ]}
                                >
                                    <DatePicker style={{ width: '100%' }} placeholder="请选择合同签署日期" disabled />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="合同金额"
                                    name="contract_amount"
                                    initialValue={detail.contract_amount || 0}
                                    rules={[
                                        {
                                            required: true,
                                            message: '请输入合同金额',
                                        },
                                    ]}
                                >
                                    <NumberInput
                                        addonAfter="元"
                                        placeholder="请输入合同金额"
                                        disabled
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="服务起止日期"
                                    name="contract_time"
                                    initialValue={detail.contract_startdate && detail.contract_enddate ? [moment(detail.contract_startdate), moment(detail.contract_enddate)] : []}
                                    rules={[{ type: 'array', required: true, message: '请选择服务起止日期' }]}
                                >
                                    <RangePicker format="YYYY-MM-DD" style={{ width: '100%' }} disabled />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="合同打印份数"
                                    name="printing_number"
                                    initialValue={detail.printing_number != null ? detail.printing_number : ""}
                                    rules={[{ required: true, message: '请填写合同打印份数' }]}
                                >
                                    <NumberInput placeholder="合同打印份数" disabled />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="寄送信息(姓名、电话、地址)"
                                    name="address"
                                    initialValue={detail.address || ''}
                                    rules={[{ required: true, message: '请填写寄送信息' }]}
                                >
                                    <Input placeholder="寄送信息" disabled />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="盖章说明"
                                    name="seal_explain"
                                    initialValue={detail.seal_explain || ''}
                                    rules={[{ required: true, message: '请填写盖章说明' }]}
                                >
                                    <Input placeholder="盖章说明" disabled />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="备注"
                                    name="remarks"
                                    initialValue={detail.remarks || ''}
                                >
                                    <Input placeholder="备注" disabled />
                                </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="纸质合同份数"
                                    name="paper_contract_number"
                                    initialValue={detail.paper_contract_number !== undefined ? detail.paper_contract_number : null}
                                >
                                    <Select placeholder="请选择纸质合同份数">
                                        <Option value={1}>1份</Option>
                                        <Option value={2}>2份</Option>
                                        <Option value={3}>3份</Option>
                                        <Option value={4}>4份</Option>
                                        <Option value={5}>5份</Option>
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="纸质合同存放位置"
                                    name="paper_contract_position"
                                    initialValue={detail.paper_contract_position || ''}
                                >
                                    <Input placeholder="请输入纸质合同存放位置" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="寄出合同单号"
                                    name="express_number"
                                    initialValue={detail.mode && detail.mode.express_number ? detail.mode.express_number : ''}
                                >
                                    <Input
                                        placeholder="请输入单号"
                                        addonBefore={
                                            <Select value={this.state.receiving_mode} style={{ width: 140 }} onChange={(value) => { this.setState({ receiving_mode: value }); if (value) { this.props.form.resetFields(['express_number']) } }}>
                                                <Select.Option value={-1}>无</Select.Option>
                                                <Select.Option value={0}>合同管理员寄出</Select.Option>
                                                <Select.Option value={1}>销售自取</Select.Option>
                                            </Select>
                                        }
                                        disabled={this.state.receiving_mode}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={{ sm: 0, md: 16, lg: 24 }}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Form.Item
                                    label="合同说明"
                                    name="contract_survey"
                                    initialValue={detail.contract_survey || ''}
                                    rules={[
                                        {
                                            required: true,
                                            message: '请填写合同说明',
                                        },
                                    ]}
                                >
                                    <TextArea rows={4} placeholder="请填写合同说明" disabled />
                                </Form.Item>
                            </Col>
                        </Row>
                        {detail.stage !== 7 || this.state.is_history !== true ?//合同申请结束后不能再上传合同
                            <>
                                <Row gutter={{ sm: 0, md: 16, lg: 24 }}>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                        <Form.Item label="上传合同单章文件">
                                            <Upload {...propsSingle} fileList={this.state.fileListSingle}>
                                                <Button>
                                                    <UploadOutlined /> 上传
                                                </Button>
                                            </Upload>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={{ sm: 0, md: 16, lg: 24 }}>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                        <Form.Item label="上传合同双章文件" >
                                            <Upload {...propsDouble} fileList={this.state.fileListDouble}>
                                                <Button>
                                                    <UploadOutlined /> 上传
                                                </Button>
                                            </Upload>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </>
                            :
                            <>
                                <DownloadLink title={'合同单章文件'} file={detail.moa_contract_file_single} isContract={true} contract={this.id} />
                                <DownloadLink title={'合同双章文件'} file={detail.moa_contract_file_double} isContract={true} contract={this.id} />
                            </>
                        }
                    </Card>
                </Card>

                <div className="submit-footer">
                    <Button type="primary" loading={this.state.submitLoading} htmlType="submit">
                        {
                            this.id ? '保存' : '提交'
                        }
                    </Button>
                </div>
            </Form>
        );
    }
}

export default PendingContractEdit;