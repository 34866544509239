import React from "react";
import {
    Card,
    Form,
    Row,
    Col,
    Input,
    Button,
    message,
    Upload,
    DatePicker,
    Select,
    Popconfirm,
    Switch,
    Spin,
} from "antd";
import moment from "moment";
import { UploadOutlined } from '@ant-design/icons';

import {
    AUTH,
    CONTRACT_HEAD_TYPE_ALL,
    CONTRACT_SUB_TYPE_OBJ,
    PROCUREMENT_CONTRACT,
    ROLES
} from "../../../../../utils/constant";
import { crmApi } from "../../../../../api";
import storage from "../../../../../utils/storage";
import NumericInput from "../../../../../components/numeric-input";
import memory from "../../../../../utils/memory";
import tools from "../../../../../utils/tools";

const {
    Option
} = Select;
const { TextArea } = Input;
const { RangePicker } = DatePicker;
const {
    reqContractAdd,
    reqContractDetail,
    reqContractEdit,
    reqContractDel,
    reqClientList,
    reqBusinessList,
    reqBusinessJackList,
    reqVendorList,
} = crmApi;
const {
    PROJECT_CONSTRACT
} = ROLES

class ContractAdd extends React.Component {
    state = {
        detail: {},
        fileList: [],
        fileList_single: [],
        fileList_double: [],
        fileLoading: false,
        clientList: [],
        businessList: [],
        connectBusiness: false,
        clientId: '',
        businessId: '',
        contract_type: undefined,
        vendorList: [],
        vendorDetail: {},
        loading: this.props.match.params.id ? true : false
    };
    id = this.props.match.params.id; //合同id
    CONTRACT_SUB_TYPE_ARR = memory.user.roles.find(o => o.type == PROJECT_CONSTRACT) ? [3, 2, 1, 4] : [3, 2, 1];
    isJack = memory.user.roles.find(o => o.type == ROLES.JACK_PROJECT) ? true : false
    formRef = React.createRef();

    /* 删除已上传文件 */
    handleRemoveFile = () => {
        this.setState({
            fileLoading: false,
        })
    }

    /*上传相关*/
    handleChange = info => {
        let fileList = [...info.fileList];
        if (info.file.status === 'uploading') {
            this.setState({
                fileLoading: true,
            })
        }
        if (info.file.status === 'done') {
            this.setState({
                fileLoading: false,
            })
        }
        // 2. Read from response and show file link
        fileList = fileList.map(file => {
            if (file.response) {
                // Component will show file.url as link
                file.url = file.response.url;
            }
            file = {
                uid: file.uid,
                name: file.name,
                status: file.status,
                url: file.url
            };
            return file;
        });

        this.setState({ fileList });
    };
    singleHandleChange = info => {
        let fileList = [...info.fileList];
        if (info.file.status === 'uploading') {
            this.setState({
                fileLoading: true,
            })
        }
        if (info.file.status === 'done') {
            this.setState({
                fileLoading: false,
            })
        }
        // 2. Read from response and show file link
        fileList = fileList.map(file => {
            if (file.response) {
                // Component will show file.url as link
                file.url = file.response.url;
            }
            file = {
                uid: file.uid,
                name: file.name,
                status: file.status,
                url: file.url
            };
            return file;
        });

        this.setState({ fileList_single: fileList });
    };
    doubleHandleChange = info => {
        let fileList = [...info.fileList];
        if (info.file.status === 'uploading') {
            this.setState({
                fileLoading: true,
            })
        }
        if (info.file.status === 'done') {
            this.setState({
                fileLoading: false,
            })
        }
        // 2. Read from response and show file link
        fileList = fileList.map(file => {
            if (file.response) {
                // Component will show file.url as link
                file.url = file.response.url;
            }
            file = {
                uid: file.uid,
                name: file.name,
                status: file.status,
                url: file.url
            };
            return file;
        });

        this.setState({ fileList_double: fileList });
    };

    handlePreview = async (file) => {
        tools.downloadFile(this.props.history, file, `${memory.user.dd_info.name} ${moment().format("YYYY/MM/DD")}`, !!this.id);
    }

    handleTypeChange = value => {
        this.setState({
            contract_type: value,
        })
    };

    validate = async (values) => {
        if (this.state.fileLoading) {
            message.error('提交失败，请先等待合同文件上传完毕');
            return;
        }
        const { fileList } = this.state;

        let response;
        values.sign_date = new Date(values.sign_date._d); //签署日期
        if (values.contract_time && values.contract_time.length) {
            values.contract_startdate = new Date(values.contract_time[0]._d); //开始时间
            values.contract_enddate = new Date(values.contract_time[1]._d); //结束时间
        }
        delete values.contract_time;
        if (fileList.length) {
            values.moa_contract_file = fileList;
        } else if (this.id == null) {
            message.warning('请上传合同文件');
            return;
        }
        if (this.state.fileList_single.length || this.state.fileList_double.length) {
            values.moa_contract_file_single = this.state.fileList_single;
            values.moa_contract_file_double = this.state.fileList_double;
        }
        if(values.vendor === 'no') {
            delete values.vendor;
        }
        if (this.id) {
            values.id = this.id;
            if (!this.state.connectBusiness) {
                values.business = null;
                values.customer = null;
            }
            response = await reqContractEdit(values);
        } else {
            if (!values.remarks) {
                delete values.remarks;
            }
            response = await reqContractAdd(values);
        }

        if (response && response.data) {
            if (this.id) {
                message.success('修改成功');
            } else {
                message.success('添加成功');
            }
            this.props.history.goBack();
        }
    }

    delContract = async () => {
        const response = await reqContractDel({
            id: this.id
        });
        if (response && response.data) {
            message.success('删除成功');
            this.props.history.replace('/moa/contract/list');
        }
    };

    handleConnectChange = () => {
        this.setState({
            connectBusiness: !this.state.connectBusiness
        })

    }

    idMessage = (_, option) => {
        this.setState({
            clientId: option.props.clienid,
            businessId: option.key,
        })
    }

    handleVendorChange = (value) => {
        if (value && value !== "no") {
            const vendorDetail = this.state.vendorList.find(o => o._id === value);
            if (vendorDetail) {
                this.setState({
                    vendorDetail: vendorDetail,
                })
            }
        } else {
            this.setState({
                vendorDetail: {},
            })
        }
    }

    getBusiness = async (_, option) => {
        let data = {
            cid: option.key,
            node: 9999,
        }
        let response;
        if (this.isJack) {
            response = await reqBusinessJackList(data);
        } else {
            response = await reqBusinessList(data)
        }
        if (response && response.data) {
            this.setState({
                businessList: response.data.data,
            })
        }
    }

    getClient = async (params = {}) => {
        const response = await reqClientList({ ...params, node: 9999 });
        if (response && response.data) {
            this.formRef.current.resetFields(['project_name']);
            this.setState({
                clientList: response.data.data,
            })
        }
    }

    getContractDetail = async (params = {}) => {
        const response = await reqContractDetail(params);
        if (response && response.data) {
            let files = [];
            let detail = response.data
            if (detail.moa_contract_file && detail.moa_contract_file.length) {
                files = detail.moa_contract_file;
                this.setState({ fileList: files });
            } else if (detail.contract_file && detail.contract_file.length && detail.contract_file[0].name) {
                detail.contract_file.forEach((value, index) => {
                    if (!value.uid) {
                        value.uid = value.name;
                    }
                });
                files = detail.contract_file || [];
                this.setState({ fileList: files });
            }
            if ((detail.moa_contract_file_single && detail.moa_contract_file_single.length) || (detail.moa_contract_file_double && detail.moa_contract_file_double.length)) {
                this.setState({
                    fileList_single: detail.moa_contract_file_single || [],
                    fileList_double: detail.moa_contract_file_double || [],
                })
            }
            if (response.data.business) {
                let clientList = [{ _id: response.data.customer, company_name: response.data.customer_name }];
                let businessList = [{ _id: response.data.business, project_name: response.data.business_name, customer: response.data.customer }];
                this.setState({
                    clientList,
                    businessList,
                })
            }
            if (PROCUREMENT_CONTRACT.indexOf(response.data.contract_type) > -1) {
                if (response.data?.vendor) {
                    const vendorDetail = this.state.vendorList.find(o => o._id === response.data.vendor);
                    if (vendorDetail) {
                        this.setState({
                            vendorDetail
                        })
                    }
                } else {
                    this.formRef.current.setFieldsValue({ vendor: "no" });
                }
            }
            this.setState({
                detail: response.data,
                connectBusiness: response.data.business ? true : false,
                contract_type: response.data.contract_type,
                loading: false
            });
        }
    };

    getVendorList = async (params = {}, callback) => {
        const response = await reqVendorList(params);
        if (response?.data) {
            this.setState({
                vendorList: response.data.msg.data,
            })
        }
        callback && callback();
    }

    componentDidMount() {
        this.getVendorList({
            page: 1,
            node: 9999,
        }, () => {
            if (this.id) {
                // 获取合同详情
                this.getContractDetail({
                    id: this.id
                });
            }
        });
    }

    render() {
        const { detail, vendorDetail, loading } = this.state;

        let headers = {};
        const auth = storage.getStore(AUTH);
        if (auth) {
            headers = {
                authorization: 'Bearer ' + auth
            };
        }

        const props = {
            action: '/api/files/ue?action=uploadfile',
            headers: headers,
            onChange: this.handleChange,
            onRemove: this.handleRemoveFile,
            multiple: true,
            accept: '.pdf',
            onPreview: this.handlePreview,
        };

        const single_props = {
            action: '/api/files/ue?action=uploadfile',
            headers: headers,
            onChange: this.singleHandleChange,
            onRemove: this.handleRemoveFile,
            multiple: true,
            accept: '.pdf',
            onPreview: this.handlePreview,
        };

        const double_props = {
            action: '/api/files/ue?action=uploadfile',
            headers: headers,
            onChange: this.doubleHandleChange,
            onRemove: this.handleRemoveFile,
            multiple: true,
            accept: '.pdf',
            onPreview: this.handlePreview,
        }

        return (
            <Form onFinish={this.validate} layout="vertical" ref={this.formRef}>
                {
                    loading ? (
                        <Spin />
                    ) : (
                        <Card
                            title={
                                this.id ? "修改合同信息" : "添加合同"
                            }
                            bordered={false}
                            style={{
                                marginBottom: 24,
                            }}
                            extra={
                                this.id ? (
                                    <Popconfirm
                                        title="确定删除该合同信息吗?"
                                        onConfirm={this.delContract}
                                        okText="确定"
                                        cancelText="取消"
                                    >
                                        <Button>删除</Button>
                                    </Popconfirm>
                                ) : null
                            }
                        >
                            <Card
                                bordered={false}
                                type="inner"
                            >
                                <Row gutter={{ sm: 0, md: 16, lg: 24 }}>
                                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                        <Form.Item
                                            label="合同名称"
                                            name="contract_name"
                                            initialValue={detail.contract_name || ''}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '请输入合同名称',
                                                },
                                            ]}
                                        >
                                            <Input placeholder="请输入合同名称" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                        <Form.Item
                                            label="合同类型"
                                            name="contract_type"
                                            initialValue={detail.contract_type || null}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '请选择合同类型',
                                                },
                                            ]}
                                        >
                                            <Select placeholder="请选择合同类型" onChange={this.handleTypeChange}>
                                                {CONTRACT_HEAD_TYPE_ALL.map((item, index) => (
                                                    <Option key={index} value={item.key}>
                                                        {item.text}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                        <Form.Item
                                            label="合同性质"
                                            name="contract_stype"
                                            initialValue={detail.contract_stype !== undefined ? detail.contract_stype : undefined}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '请选择合同性质',
                                                },
                                            ]}
                                        >
                                            <Select
                                                placeholder="请选择合同性质"
                                            >
                                                {this.CONTRACT_SUB_TYPE_ARR.map((item, index) => (
                                                    <Option key={item} value={item}>
                                                        {CONTRACT_SUB_TYPE_OBJ[item].text}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                        <Form.Item
                                            label="合同编号"
                                            name="contract_number"
                                            initialValue={detail.contract_number || ''}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '请输入合同编号',
                                                },
                                            ]}
                                        >
                                            <Input placeholder="请输入合同编号" />
                                        </Form.Item>
                                    </Col>
                                    {
                                        this.state.contract_type && PROCUREMENT_CONTRACT.indexOf(this.state.contract_type) > -1 ?
                                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                                <Form.Item
                                                    label="供应商"
                                                    name="vendor"
                                                    initialValue={detail?.vendor || undefined}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: '请选择供应商',
                                                        },
                                                    ]}
                                                >
                                                    <Select
                                                        placeholder="请选择供应商"
                                                        showSearch
                                                        optionFilterProp="children"
                                                        onChange={this.handleVendorChange}
                                                    >
                                                        <Select.Option key={"no"} value={"no"}>无</Select.Option>
                                                        {this.state.vendorList.map((value) => (
                                                            <Select.Option key={value._id} value={value._id}>{value.name}</Select.Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            :
                                            null
                                    }
                                    {
                                        vendorDetail?._id ?
                                            <>
                                                <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                                    <Form.Item
                                                        label="供应商社会信用码"
                                                    >
                                                        <Input placeholder="请填写供应商社会信用码" value={vendorDetail.social_credit_code} disabled />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                                    <Form.Item
                                                        label="供应商地址"
                                                    >
                                                        <Input placeholder="请填写供应商地址" value={vendorDetail.address} disabled />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                                    <Form.Item
                                                        label="供应商电话"
                                                    >
                                                        <Input placeholder="请填写供应商电话" value={vendorDetail.telephone} disabled />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                                    <Form.Item
                                                        label="供应商开户银行"
                                                    >
                                                        <Input placeholder="请填写供应商开户银行" value={vendorDetail.bank} disabled />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                                    <Form.Item
                                                        label="供应商开户银行账号"
                                                    >
                                                        <Input placeholder="请填写供应商开户银行账号" value={vendorDetail.bank_account} disabled />
                                                    </Form.Item>
                                                </Col>
                                            </>
                                            :
                                            null
                                    }
                                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                        <Form.Item
                                            label="合同签署日期"
                                            name="sign_date"
                                            initialValue={detail.sign_date ? moment(detail.sign_date) : undefined}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '请选择合同签署日期',
                                                },
                                            ]}
                                        >
                                            <DatePicker style={{ width: '100%' }} placeholder="请选择合同签署日期" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                        <Form.Item
                                            label="合同金额"
                                            name="contract_amount"
                                            initialValue={detail.contract_amount || 0}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '请输入合同金额',
                                                },
                                            ]}
                                        >
                                            <NumericInput
                                                addonAfter="元"
                                                placeholder="请输入合同金额"
                                            />
                                        </Form.Item>
                                    </Col>

                                    {this.state.connectBusiness === false ?
                                        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                            <Form.Item
                                                label="客户名称"
                                                name="customer_name"
                                                initialValue={detail.customer_name || ''}
                                            >
                                                <Input placeholder="请输入客户名称" />
                                            </Form.Item>
                                        </Col>
                                        : null
                                    }

                                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                        <Form.Item
                                            label="纸质合同份数"
                                            name="paper_contract_number"
                                            initialValue={detail.paper_contract_number !== undefined ? detail.paper_contract_number : undefined}
                                        >
                                            <Select placeholder="请选择纸质合同份数">
                                                <Option value={1}>1份</Option>
                                                <Option value={2}>2份</Option>
                                                <Option value={3}>3份</Option>
                                                <Option value={4}>4份</Option>
                                                <Option value={5}>5份</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                        <Form.Item
                                            label="纸质合同存放位置"
                                            name="paper_contract_position"
                                            initialValue={detail.paper_contract_position || ''}
                                        >
                                            <Input placeholder="请输入纸质合同存放位置" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                        <Form.Item
                                            label="起止日期"
                                            name="contract_time"
                                            initialValue={detail.contract_startdate && detail.contract_enddate ? [moment(detail.contract_startdate), moment(detail.contract_enddate)] : []}
                                        >
                                            <RangePicker format="YYYY-MM-DD" style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                        <Form.Item
                                            label="备注"
                                            name="remarks"
                                            initialValue={detail.remarks || ''}
                                        >
                                            <Input placeholder="备注" />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={{ sm: 0, md: 16, lg: 24 }}>
                                    <Col xs={24} sm={24} md={12} lg={24} xl={24}>
                                        <Switch
                                            unCheckedChildren="关联项目"
                                            checkedChildren="关联项目"
                                            defaultChecked={false}
                                            onChange={this.handleConnectChange}
                                            style={{ marginBottom: 24 }}
                                            checked={this.state.connectBusiness}
                                        />
                                    </Col>
                                </Row>

                                {this.state.connectBusiness ?
                                    <Row gutter={{ sm: 0, md: 16, lg: 24 }}>
                                        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                            <Form.Item
                                                label="客户名称"
                                                name="customer"
                                                initialValue={detail.customer_name !== undefined ? detail.customer : undefined}
                                                rules={[{
                                                    required: true,
                                                    message: '请选择客户名称',
                                                }]}
                                            >
                                                <Select
                                                    placeholder="请选择客户名称"
                                                    showSearch
                                                    onSelect={this.getBusiness}
                                                    onSearch={(value) => value ? this.getClient({ company_name: value }) : this.setState({ clientList: [] })}
                                                    filterOption={(input, option) =>
                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {
                                                        this.state.clientList.map((value, index) => {
                                                            return <Option key={value._id} value={value._id}>{value.company_name}</Option>
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                            <Form.Item
                                                label="项目名称"
                                                name="business"
                                                initialValue={detail.business_name !== undefined ? detail.business : undefined}
                                                rules={[{
                                                    required: true,
                                                    message: '请选择项目',
                                                }]}
                                            >
                                                <Select
                                                    placeholder="请选择项目"
                                                    showSearch
                                                    onSelect={this.idMessage}
                                                    filterOption={(input, option) =>
                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {
                                                        this.state.businessList.map((value, index) => {
                                                            return <Option key={value._id} value={value._id} clientid={value.customer}>{value.project_name}</Option>
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    : null
                                }

                                <Row gutter={{ sm: 0, md: 16, lg: 24 }}>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                        <Form.Item
                                            label="合同说明"
                                            name="contract_survey"
                                            initialValue={detail.contract_survey || ''}
                                        >
                                            <TextArea rows={4} placeholder="请填写合同说明" />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                {this.id == null || (detail.contract_file && detail.contract_file.length && detail.contract_file[0].name) || (detail.moa_contract_file && detail.moa_contract_file.length) ?
                                    <Row gutter={{ sm: 0, md: 16, lg: 24 }}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            <Form.Item label="上传合同扫描文件" required>
                                                <Upload {...props} fileList={this.state.fileList}>
                                                    <Button>
                                                        <UploadOutlined /> 上传
                                                    </Button>
                                                </Upload>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    :
                                    null
                                }
                                {this.id != null && ((detail.moa_contract_file_single && detail.moa_contract_file_single.length) || (detail.moa_contract_file_double && detail.moa_contract_file_double.length) || (!this.state.fileList.length)) ?
                                    <>
                                        <Row gutter={{ sm: 0, md: 16, lg: 24 }}>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                <Form.Item label="上传合同单章文件" required>
                                                    <Upload {...single_props} fileList={this.state.fileList_single}>
                                                        <Button>
                                                            <UploadOutlined /> 上传
                                                        </Button>
                                                    </Upload>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={{ sm: 0, md: 16, lg: 24 }}>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                <Form.Item label="上传合同双章文件" required>
                                                    <Upload {...double_props} fileList={this.state.fileList_double}>
                                                        <Button>
                                                            <UploadOutlined /> 上传
                                                        </Button>
                                                    </Upload>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </>
                                    :
                                    null
                                }
                            </Card>
                        </Card>
                    )
                }

                <div className="submit-footer">
                    <Button type="primary" htmlType="submit">
                        {
                            this.id ? '保存' : '提交'
                        }
                    </Button>
                </div>
            </Form>
        );
    }
}

export default ContractAdd;