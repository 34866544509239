import React, { lazy, Suspense } from 'react';
import {
    Layout,
    Popover,
    Avatar,
    Button,
    message,
    Breadcrumb,
    Spin,
    ConfigProvider,
    Badge
} from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import { Link, Route, Switch } from "react-router-dom";
import * as dd from 'dingtalk-jsapi';
import isMobile from "ismobilejs";
import moment from 'moment';
import 'moment/locale/zh-cn';
import { BellOutlined, MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';

import LeftNav from "./components/left-nav/LeftNav";
import {
    AUTH,
    ROLES,
    UserInfoContext,
    CORPID,
    AVATAR,
    AGENTID,
    SWITCH_ACCOUNT_WHITELIST
} from "../../utils/constant";
import memory from "../../utils/memory";
import { reqSignout, crmApi, reqSelfInfo, reqDepartmentList, siteMessageApi } from "../../api";
import storage from "../../utils/storage";
import menuList from "./config/menu-config";
import tools from '../../utils/tools';
import NoticeList from './components/notice-list';
import "./index.less";

const { reqMessageList, reqUnreadMessageAmount } = siteMessageApi;

const Home = lazy(() => import('./routes/home'));
const BusinessList = lazy(() => import('./routes/client/business-list'));
const ClientList = lazy(() => import('./routes/client'));
const Add = lazy(() => import('./routes/client/add'));
const Detail = lazy(() => import('./routes/client/detail'));

const KpList = lazy(() => import('./routes/client/kp'));
const KPAdd = lazy(() => import('./routes/client/kp-add'));
const KPDetail = lazy(() => import('./routes/client/kp-detail'));
const BusinessAdd = lazy(() => import('./routes/client/business-add'));
const BusinessDetail = lazy(() => import('./routes/client/business-detail'));
const ContractAdd = lazy(() => import('./routes/client/contract-add'));
const ScheduleAdd = lazy(() => import('./routes/client/schedule-add'));
const ClientTagList = lazy(() => import('./routes/tags'));
const InvoiceAdd = lazy(() => import('./routes/client/invoice/add'));
const InvoiceApprove = lazy(() => import('./routes/client/invoice/approve'));
const InvoiceApproveList = lazy(() => import('./routes/client/invoice/approve-list'));
const InvoiceApproveHistoryList = lazy(() => import('./routes/client/invoice/approve-list/history'));
const MyInvoiceList = lazy(() => import('./routes/client/invoice/my-list'));
const MyInvoiceDetail = lazy(() => import('./routes/client/invoice/my-detail'));
const MyCostList = lazy(() => import('./routes/salesman/my-cost'));

const MarketCostAdd = lazy(() => import('./routes/client/marketCost-add'))

const TargetList = lazy(() => import('./routes/salesman'));
const MyLog = lazy(() => import('./routes/salesman/my-log'));
const LogDetail = lazy(() => import('./routes/salesman/my-log/log-detail'));
const MyReturnList = lazy(() => import("./routes/salesman/my-returnlist"));
const MyReturnDetail = lazy(() => import("./routes/salesman/my-returnlist/detail"));
const MyGoOutList = lazy(() => import("./routes/salesman/my-go-out"));
const MyGoOutDetail = lazy(() => import("./routes/salesman/my-go-out/detail"));
const MyGoOutAdd = lazy(() => import("./routes/salesman/my-go-out/add"));

const DailyLog = lazy(() => import('./routes/client/daily-log'));
const DailyLogAdd = lazy(() => import('./routes/client/daily-log/add'));
const DailyLogApproveNewDetail = lazy(() => import('./routes/client/daily-log/approve'));
const DailyLogNewDetail = lazy(() => import('./routes/client/daily-log/detail'));

const MyContractList = lazy(() => import('./routes/salesman/my-contract'));
const MyContractDetail = lazy(() => import('./routes/salesman/my-contract/detail'));

const SellerWeaponList = lazy(() => import('./routes/salesman/weapon'));
const SellerWeaponDetail = lazy(() => import('./routes/salesman/weapon/detail'));

const MyMarketCostList = lazy(() => import('./routes/salesman/my-marketCost'))
const MyMarketCostDetail = lazy(() => import('./routes/salesman/my-marketCost/detail'))
const MyPreSellerApplyList = lazy(() => import('./routes/salesman/my-preseller'));
const MyPreSellerApplyHistory = lazy(() => import('./routes/salesman/my-preseller/history'));

const PreSellerApplyList = lazy(() => import('./routes/pre-seller/apply'));
const PreSellerApplyDetail = lazy(() => import('./routes/pre-seller/apply/detail'));
const PreSellerApplyHistory = lazy(() => import('./routes/pre-seller/apply/history'));

const SalesmanList = lazy(() => import('./routes/admin/salesman'));
const SalesmanAdd = lazy(() => import('./routes/admin/salesman/add'));
const SalesmanDetail = lazy(() => import('./routes/admin/salesman/detail'));
const DepartmentList = lazy(() => import('./routes/admin/department'));
const DepartmentAdd = lazy(() => import('./routes/admin/department/add'));
const WinList = lazy(() => import('./routes/admin/returned-apply'));
const ReturnedApplyDetail = lazy(() => import('./routes/admin/returned-apply/detail'));
const AdminBusinessList = lazy(() => import('./routes/admin/business'));
const WorkLogList = lazy(() => import('./routes/admin/work-log'));
const ContractList = lazy(() => import('./routes/admin/contract'));
const ContractDetail = lazy(() => import('./routes/admin/contract/detail'));
const SellerTarget = lazy(() => import('./routes/admin/target'));
const AdminDailyWorkLog = lazy(() => import('./routes/admin/daily-log'));
const ContractAdminList = lazy(() => import('./routes/admin/constract-apply'));
const ContractAdminDetail = lazy(() => import('./routes/admin/constract-apply/detail'));
const Tags = lazy(() => import('./routes/admin/tags'));
const WeaponAdd = lazy(() => import('./routes/admin/weapon/add'));
const WeaponList = lazy(() => import('./routes/admin/weapon'));
const WeaponDetail = lazy(() => import('./routes/admin/weapon/detail'));
const AdminInvoiceList = lazy(() => import('./routes/admin/invoice-list'));
const AdminReturnList = lazy(() => import('./routes/admin/return-list'));
const ReturnBindBusiness = lazy(() => import('./routes/admin/return-list/bind-business'));
const AdminInvoiceDetail = lazy(() => import('./routes/admin/invoice-list/detail'));
const AdminReturnDetail = lazy(() => import('./routes/admin/return-list/detail'));
const AdminInvoiceAdd = lazy(() => import('./routes/admin/contract/invoice-add'));

const AdminMarketCostList = lazy(() => import('./routes/admin/marketCost-list'))
const AdminMarketCostDetail = lazy(() => import('./routes/admin/marketCost-list/detail'))
const AdminTrackDetail = lazy(() => import("./routes/admin/track"))
const AdminCommisionList = lazy(() => import("./routes/admin/commission/"))
const AdminCommisionListHistory = lazy(() => import("./routes/admin/commission/history"))
const AdminCommisionCheck = lazy(() => import("./routes/admin/commission/detail"))
const AdminClockAttendanceList = lazy(() => import("./routes/admin/clock-attendance"))
const AdminClockAttendanceDetail = lazy(() => import("./routes/admin/clock-attendance/detail"))
const AdminSellerReturnList = lazy(() => import("./routes/admin/seller-return-list"));

const WorkLogApplyList = lazy(() => import('./routes/approve/work-log-apply'));
const CostApplyList = lazy(() => import('./routes/approve/cost-apply'));
const CostApplyHistory = lazy(() => import('./routes/approve/cost-apply/history'));
const CostApplyDetail = lazy(() => import('./routes/approve/cost-apply/detail'));
const SellerAdminApplyList = lazy(() => import('./routes/approve/seller-admin-apply'));
const SellerAdminApplyHistory = lazy(() => import('./routes/approve/seller-admin-apply/history'));
const SellerAdminApplyDetail = lazy(() => import('./routes/approve/seller-admin-apply/detail'));
const WinApplyList = lazy(() => import('./routes/approve/win-apply'));
const WinApplyDetail = lazy(() => import('./routes/approve/win-apply/detail'));
const WinApplyHistory = lazy(() => import('./routes/approve/win-apply/history'));
const TechnologyApproveList = lazy(() => import('./routes/approve/technology-approve'));
const TechnologyApproveDetail = lazy(() => import('./routes/approve/technology-approve/detail'));
const TechnologyApproveHistory = lazy(() => import('./routes/approve/technology-approve/history'));
const LawApproveList = lazy(() => import('./routes/approve/law-approve'));
const LawApproveDetail = lazy(() => import('./routes/approve/law-approve/detail'));
const LawApproveHistory = lazy(() => import('./routes/approve/law-approve/history'));
const WorkLogCheckList = lazy(() => import('./routes/approve/work-log-check'));
const WorkLogCheckManagerList = lazy(() => import('./routes/approve/work-log-check/manager'));

const MarketCostFinanceList = lazy(() => import('./routes/approve/market-finance-apply'));
const MarketCostFinanceDetail = lazy(() => import('./routes/approve/market-finance-apply/detail'));
const MarketCostFinanceHistory = lazy(() => import('./routes/approve/market-finance-apply/history'));
const MarketCostFinalList = lazy(() => import('./routes/approve/market-final-apply'));
const MarketCostFinalDetail = lazy(() => import('./routes/approve/market-final-apply/detail'));
const MarketCostFinalHistory = lazy(() => import('./routes/approve/market-final-apply/history'));

const GoOutApplyList = lazy(() => import("./routes/approve/go-out-apply"));
const GoOutApplyHistory = lazy(() => import("./routes/approve/go-out-apply/history"));
const GoOutApplyDetail = lazy(() => import("./routes/approve/go-out-apply/detail"));
const SellerGoOutApplyList = lazy(() => import("./routes/approve/seller-go-out-apply"));
const SellerGoOutApplyDetail = lazy(() => import("./routes/approve/seller-go-out-apply/detail"));
const SellerGoOutApplyHistory = lazy(() => import("./routes/approve/seller-go-out-apply/history"));

const AnnouncementList = lazy(() => import('./routes/system/announcement'));
const AnnouncementDetail = lazy(() => import('./routes/system/announcement/detail'));

const MessageList = lazy(() => import("./routes/system/message"));
const MessageDetail = lazy(() => import("./routes/system/message/detail"));

const DiagramDetail = lazy(() => import("./routes/admin/diagram-mark/diagram-detail"));
const DiagramMark = lazy(() => import("./routes/admin/diagram-mark"));
const MyDiagramDetail = lazy(() => import("./routes/salesman/my-diagram"));

const ContractFileApplyList = lazy(() => import("./routes/approve/contract-file-apply"));
const ContractFileApplyHistory = lazy(() => import("./routes/approve/contract-file-apply/history"));
const ContractFileApplyDetail = lazy(() => import("./routes/approve/contract-file-apply/detail"));
const MyContractFileApplyList = lazy(() => import("./routes/client/my-contract-file-apply"));
const MyContractFileApplyDetail = lazy(() => import("./routes/client/my-contract-file-apply/detail"));

const FinanceVendorList = lazy(() => import("./routes/finance/vendor"));
const FinanceVendorDetail = lazy(() => import("./routes/finance/vendor/detail"));
const FinanceVendorAdd = lazy(() => import("./routes/finance/vendor/add"));
const FinanceDisbursementList = lazy(() => import("./routes/finance/disbursement"));
const FinanceDisbursementDetail = lazy(() => import("./routes/finance/disbursement/detail"));
const FinanceInvoiceList = lazy(() => import("./routes/finance/invoice"));
const FinanceInvoiceDetail = lazy(() => import("./routes/finance/invoice/detail"));
const FinanceInvoiceAdd = lazy(() => import("./routes/finance/invoice/add"));

const QuotationAdd = lazy(() => import("./routes/client/quotation/add"));
const MyQuotationList = lazy(() => import("./routes/client/quotation/salesman"));
const MyQuotationDetail = lazy(() => import("./routes/client/quotation/salesman/detail"));
const QuotationApproveList = lazy(() => import("./routes/client/quotation/approve"));
const QuotationApproveHistoryList = lazy(() => import("./routes/client/quotation/approve/history"));
const QuotationApproveDetail = lazy(() => import("./routes/client/quotation/approve/detail"));

const Attendance = lazy(() => import('./routes/attendance'));
const Compensation = lazy(() => import('./routes/compensation'));

const DeviceList = lazy(() => import('../admin/routes/device'));
const DeviceAdd = lazy(() => import('../admin/routes/device/add'));

//商机列表
const CommercialOpportunity=lazy(()=>import("./routes/commercial-opportunity"))
moment.locale('zh-cn');

const { Header, Content, Footer, Sider } = Layout;
const {
    reqTrades,
    reqLogin,
    reqSelf,
    reqDDAuth,
    reqMobileListInfo,
} = crmApi;
const { department_obj, crm_global } = memory;
const {
    RIGHTS,
    SELLER
} = ROLES;

export default class Index extends React.Component {
    static contextType = UserInfoContext;

    state = {
        loading: true,
        collapsed: isMobile(navigator.userAgent).any ? true : false,
    };
    breadcrumb = [];

    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };

    userInfoHeader = () => {
        return (
            <div className="pop-header">
                <Avatar size="small" className="pop-avatar" src={memory.user.dd_info.avatar} />
                <span className="pop-name">{memory.user.dd_info.name}</span>
            </div>
        );
    }
    userInfoContent = () => {
        return (
            <div className="pop-content">
                <Link to="/crm/" className="pop-content-item">
                    <span className="item-name">个人中心</span>
                </Link>
                {
                    memory.user.role_type.indexOf(RIGHTS) > -1 ? (
                        <Link to="/admin" className="pop-content-item">
                            <span className="item-name">后台管理</span>
                        </Link>
                    ) : null
                }
                <div className="pop-content-info">
                    <div className="info-item">
                        <label className="info-item-label">部门：</label>
                        <span className="info-item-name">{department_obj[memory.user.dd_info.department[0]]}</span>
                    </div>
                </div>
                {
                    dd.env.platform !== 'notInDingTalk' ? null : (
                        <footer className="pop-content-footer">
                            {
                                SWITCH_ACCOUNT_WHITELIST.indexOf(memory.user.dd_info.name) > -1 ?
                                    <div>
                                        <Button type="link" onClick={() => this.props.history.push("/switch/account")}>切换账号</Button>
                                    </div>
                                    :
                                    null
                            }
                            <div>
                                <Button type="link" onClick={this.handleSignout}>退出登录</Button>
                            </div>
                        </footer>
                    )
                }
            </div>
        );
    }

    breadcrumbComponent = (menuArr, pathname) => {
        for (let i = 0; i < menuArr.length; i++) {
            if (pathname.indexOf(menuArr[i].key) === 0) {
                if (menuArr[i].key === '/crm') {
                    this.breadcrumb.push(
                        <Breadcrumb.Item key={menuArr[i].key}>
                            <Link to="/crm">
                                {menuArr[i].title}
                            </Link>
                        </Breadcrumb.Item>
                    );
                } else {
                    this.breadcrumb.push(
                        <Breadcrumb.Item key={menuArr[i].key}>
                            {menuArr[i].title}
                        </Breadcrumb.Item>
                    );
                }
                if (menuArr[i].children) {
                    this.breadcrumbComponent(menuArr[i].children, pathname);
                }
            }
        }
        return this.breadcrumb;
    }

    handleSignout = () => {
        (async () => {
            const response = await reqSignout();
            if (response) {
                message.success('账号已退出');
                storage.removeStore(AUTH);
                this.props.history.replace('/');
            }
        })();
    }

    getTradeList = async (params = {}) => {
        const response = await reqTrades(params);
        const data = response.data;
        if (data) {
            crm_global.trade_arr = data || [];
            crm_global.trade_obj = {};
            for (let i = 0; i < data.length; i++) {
                crm_global.trade_obj[data[i]._id] = data[i].name;
            }
            this.setState({
                loading: false
            });
        }
    };

    getNoticeAmount = async () => {
        const response = await reqUnreadMessageAmount();
        if (response?.data) {
            this.context.setState({ unreadMessageAmount: response.data.msg });
        }
    }

    getNoticeList = async () => {
        const response = await reqMessageList({ page: 1, node: 50 });
        if (response?.data) {
            this.context.setState({ siteMessageList: response.data.data });
        }
    }

    toLogin = async (code) => {
        const response = await reqLogin({
            code: code,
        });
        const auth = response.data.token;
        storage.saveStore(AUTH, auth);
        message.success('登录成功');

        let url = window.location.href;
        const params = {
            url: url,
            timeStamp: (new Date()).getTime().toString()
        };
        this.getDDAuth(params);

        // 获取个人信息
        const self = await reqSelfInfo();
        if (self && self.data) {
            dd.device.base.getPhoneInfo({
                onSuccess: async function (data) {
                    const response1 = await reqMobileListInfo(data);
                    if (response1) {
                    }
                },
                onFail: function (err) {
                },
            })
            memory.user = self.data;
            memory.user.dd_info.avatar = memory.user.dd_info.avatar || AVATAR;
            memory.user.role_type = [];
            if (self.data.roles && self.data.roles.length) {
                for (let i = 0; i < self.data.roles.length; i++) {
                    memory.user.role_type.push(self.data.roles[i].type);
                }
            }
            if (memory.user.role_type.indexOf(SELLER) > -1) {
                const seller = await reqSelf();
                if (seller && seller.data) {
                    memory.seller = seller.data;
                    if (memory.seller.overdue_business && memory.seller.overdue_business.length) {
                        message.warning(`当前有${memory.seller.overdue_business.length}条到期自动关闭项目未填写原因，请先填写`)
                        this.props.history.push(`/crm/client/schedule-add/lose/${memory.seller.overdue_business[0]}`)
                    }
                }
            }
            // 获取部门信息
            const dep = await reqDepartmentList();
            if (dep) {
                memory.departments = dep.data;
                for (let i = 0; i < dep.data.length; i++) {
                    memory.department_obj[dep.data[i].id] = dep.data[i].name;
                }
            }
            this.getTradeList();
            this.getNoticeAmount();
            this.getNoticeList();
        }
    }

    getDDAuth = async (params = {}) => {
        const response = await reqDDAuth(params);
        if (response && response.data) {
            this.sign = response.data.sign;
            memory.sign = response.data.sign;
            this.nonceStr = response.data.nonceStr;
            memory.nonceStr = response.data.nonceStr;
            const options = {
                agentId: AGENTID,
                corpId: CORPID,
                timeStamp: params.timeStamp,
                nonceStr: response.data.nonceStr,
                signature: response.data.signature,
                jsApiList: [
                    'device.geolocation.get', //获取当前地理位置信息
                    'device.geolocation.start', //持续获取当前地理位置信息id
                    'device.geolocation.stop', //停止持续获取当前地理位置信息
                    'device.geolocation.status',//获取持续定位id
                    'biz.map.locate', //地图定位
                    'biz.map.search', //展示位置
                    'device.base.getPhoneInfo',//获取登录的手机设备信息
                    'biz.clipboardData.setData',
                    'biz.cspace.chooseSpaceDir',
                    'biz.util.uploadAttachment',
                    'biz.cspace.preview'
                ]
            };
            dd.config(options);
        }
    };

    componentDidMount = () => {
        let url = window.location.href;
        if (url.split('?').length > 1 && tools.queryString('out_trade_no', url)) {
            if (dd.env.platform !== 'notInDingTalk') {
                url = url.split('?')[0];
                this.props.history.replace('/crm/blank');
                window.location.href = window.location.href;
                return;
            } else {
                window.opener = null;
                window.open('', '_self');
                window.close();
                return;
            }
        }
        const getTradeFun = this.getTradeList;
        const auth = storage.getStore(AUTH);
        const { history } = this.props;
        if (dd.env.platform !== 'notInDingTalk') {
            const that = this;
            dd.ready(function () {
                dd.runtime.permission.requestAuthCode({
                    corpId: CORPID, // 企业id
                    onSuccess: function (info) {
                        const code = info.code; // 通过该免登授权码可以获取用户身份
                        that.toLogin(code);
                    },
                    onFail: function (err) {
                        message.error('requestAuthCode error: ' + JSON.stringify(err));
                    }
                });
            });
            dd.error(function (error) {
                message.error('dd error: ' + JSON.stringify(error));
            });
        } else {
            if (auth) {
                (async () => {
                    if (memory.user.role_type.indexOf(SELLER) > -1) {
                        const seller = await reqSelf();
                        if (seller && seller.data) {
                            memory.seller = seller.data;
                            if (memory.seller.overdue_business && memory.seller.overdue_business.length) {
                                message.warning(`当前有${memory.seller.overdue_business.length}条到期自动关闭项目未填写原因，请先填写`)
                                history.push(`/crm/client/schedule-add/lose/${memory.seller.overdue_business[0]}`)
                            }
                        }
                    }
                    getTradeFun();
                    this.getNoticeAmount();
                    this.getNoticeList();
                })();
            } else {
                history.replace('/');
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (memory.is_mobile) {
            if (this.props.location.pathname !== prevProps.location.pathname) {
                this.setState({
                    collapsed: true
                });
            }
        }
    }

    componentWillUpdate() {
        this.breadcrumb = [];
    }

    componentWillReceiveProps(newProps) {
        let { history } = this.props
        let location = this.props.location
        const auth = storage.getStore(AUTH);
        const getTradeFun = this.getTradeList;

        //如果有路由跳转，并且是从过期项目填写原因跳转过来，就判断是否还有过期项目未填写原因
        if (location.pathname !== newProps.location.pathname && location.pathname.indexOf('/crm/client/schedule-add/') > -1 && memory.seller.overdue_business.length) {
            if (auth) {
                (async () => {
                    if (memory.user.role_type.indexOf(SELLER) > -1) {
                        const seller = await reqSelf();
                        if (seller && seller.data) {
                            memory.seller = seller.data;
                            if (memory.seller.overdue_business && memory.seller.overdue_business.length) {
                                message.warning(`当前有${memory.seller.overdue_business.length}条到期自动关闭项目未填写原因，请先填写`)
                                history.push(`/crm/client/schedule-add/lose/${memory.seller.overdue_business[0]}`)
                            }
                        }
                    }
                    getTradeFun();
                    this.getNoticeAmount();
                    this.getNoticeList();
                })();
            } else {
                history.replace('/');
            }
        }
    }

    render() {
        const pathname = this.props.location.pathname;
        return (
            <Layout className="crm">
                {
                    this.state.loading ? (
                        <div className="loading-container">
                            <Spin size="large" />
                        </div>
                    ) : (
                        <ConfigProvider locale={zhCN}>
                            <Sider
                                breakpoint="lg"
                                collapsedWidth={memory.is_mobile ? 0 : 80}
                                trigger={null}
                                collapsible
                                collapsed={this.state.collapsed}
                                style={{
                                    overflow: 'auto',
                                    height: '100%',
                                    position: 'fixed',
                                    left: 0,
                                    zIndex: 999,
                                }}
                            >
                                <div className="crm-logo" title="首页">
                                    <Link to="/crm">
                                        {
                                            memory.is_mobile ? (
                                                <span className="logo-name" style={{ color: '#ccc', fontSize: 26, marginLeft: 20 }}>CRM</span>
                                            ) : (
                                                this.state.collapsed ? null : (
                                                    <span className="logo-name" style={{ color: '#ccc', fontSize: 26, marginLeft: 20 }}>CRM</span>
                                                )
                                            )
                                        }
                                    </Link>
                                </div>
                                {
                                    <LeftNav setState={() => this.setState({})} />
                                }
                            </Sider>

                            <Layout
                                style={{
                                    marginLeft: memory.is_mobile ? 0 : (this.state.collapsed ? 80 : 200),
                                }}
                            >
                                <Header
                                    className="crm-header"
                                    style={{
                                        position: 'fixed',
                                        zIndex: 97,
                                        left: 0,
                                        right: 0,
                                        marginLeft: memory.is_mobile ? 0 : (this.state.collapsed ? 80 : 200)
                                    }}
                                >
                                    {
                                        this.state.collapsed ? <MenuUnfoldOutlined className="crm-trigger" onClick={this.toggle} /> : <MenuFoldOutlined className="crm-trigger" onClick={this.toggle} />
                                    }

                                    <div className="user">
                                        {
                                            !!memory.user?.roles?.find(o => o.type === ROLES.SELLER || o.type === ROLES.PRESELLER) ?
                                                <Link to="/crm/salesman/daily/add"><Button type="primary" size='small' style={{ marginRight: 16 }}>提交日报</Button></Link>
                                                :
                                                null
                                        }
                                        <Popover trigger="click" arrowPointAtCenter={true} placement="bottomRight" content={<NoticeList history={this.props.history} setState={() => this.setState({})} />}>
                                            <Badge className='notice' count={this.context.unreadMessageAmount} style={{ height: 16, lineHeight: "16px", padding: 0, cursor: "pointer" }}>
                                                <BellOutlined style={{ fontSize: 20 }} />
                                            </Badge>
                                        </Popover>
                                        <Popover className="popover" content={this.userInfoContent()} title={this.userInfoHeader()} placement="bottomRight">
                                            <Avatar className="avatar" src={memory.user.dd_info.avatar} />
                                        </Popover>
                                    </div>
                                </Header>

                                {
                                    pathname === '/crm' || pathname.indexOf('/crm/admin/salesman/dashboard/') > -1 ? null : (
                                        <Breadcrumb
                                            style={{
                                                padding: '24px 24px 0',
                                                marginTop: '64px',
                                            }}>
                                            {this.breadcrumbComponent(menuList(memory), pathname)}
                                        </Breadcrumb>
                                    )
                                }

                                <Content style={pathname === '/crm' || pathname.indexOf('/crm/admin/salesman/dashboard/') > -1 ? {} : { margin: '24px 16px 0' }}>
                                    <Suspense fallback={<Spin size="small" />}>
                                        <Switch>
                                            <Route path="/crm/equipment/edit/:id" component={DeviceAdd} ></Route>
                                            <Route path="/crm/equipment/add" component={DeviceAdd} ></Route>
                                            <Route path="/crm/equipment/list" component={DeviceList} ></Route>
                                            <Route path="/crm/compensation" component={Compensation} ></Route>
                                            <Route path="/crm/attendance" component={Attendance} ></Route>
                                            <Route path="/crm/approve/quotation/detail/:id" component={QuotationApproveDetail} ></Route>
                                            <Route path="/crm/approve/quotation/history" component={QuotationApproveHistoryList} ></Route>
                                            <Route path="/crm/approve/quotation/list" component={QuotationApproveList} ></Route>
                                            <Route path="/crm/salesman/quotation/detail/:id" component={MyQuotationDetail} ></Route>
                                            <Route path="/crm/quotation/add/:bid" component={QuotationAdd} ></Route>
                                            <Route path="/crm/salesman/quotation/list" component={MyQuotationList} ></Route>
                                            <Route path="/crm/finance/disbursement/invoice-add/:did" component={FinanceInvoiceAdd} ></Route>
                                            <Route path="/crm/finance/invoice/list" component={FinanceInvoiceList} ></Route>
                                            <Route path="/crm/finance/invoice/detail/:id" component={FinanceInvoiceDetail} ></Route>
                                            <Route path="/crm/finance/invoice/add" component={FinanceInvoiceAdd} ></Route>
                                            <Route path="/crm/finance/invoice/edit/:id" component={FinanceInvoiceAdd} ></Route>
                                            <Route path="/crm/finance/disbursement/list" component={FinanceDisbursementList} ></Route>
                                            <Route path="/crm/finance/disbursement/detail/:id" component={FinanceDisbursementDetail} ></Route>
                                            <Route path="/crm/finance/vendor/list" component={FinanceVendorList} ></Route>
                                            <Route path="/crm/finance/vendor/detail/:id" component={FinanceVendorDetail} ></Route>
                                            <Route path="/crm/finance/vendor/add" component={FinanceVendorAdd} ></Route>
                                            <Route path="/crm/finance/vendor/edit/:id" component={FinanceVendorAdd} ></Route>

                                            <Route path="/crm/my/contract-file-apply/list" component={MyContractFileApplyList} ></Route>
                                            <Route path="/crm/my/contract-file-apply/detail/:id" component={MyContractFileApplyDetail} ></Route>


                                            <Route path='/crm/approve/marketCost-final/list' component={MarketCostFinalList} ></Route>
                                            <Route path='/crm/approve/marketCost-final/detail/:id' component={MarketCostFinalDetail} ></Route>
                                            <Route path='/crm/approve/marketCost-final/history' component={MarketCostFinalHistory} ></Route>
                                            <Route path='/crm/approve/marketCost-finance/list' component={MarketCostFinanceList} ></Route>
                                            <Route path='/crm/approve/marketCost-finance/detail/:id' component={MarketCostFinanceDetail} ></Route>
                                            <Route path='/crm/approve/marketCost-finance/history' component={MarketCostFinanceHistory} ></Route>
                                            <Route path="/crm/approve/work-log-apply/new-detail/:id" component={DailyLogApproveNewDetail}></Route>
                                            <Route path="/crm/approve/work-log-apply/list" component={WorkLogApplyList}></Route>
                                            <Route path="/crm/approve/cost-apply/detail/:id" component={CostApplyDetail}></Route>
                                            <Route path="/crm/approve/cost-apply/history" component={CostApplyHistory}></Route>
                                            <Route path="/crm/approve/cost-apply/list" component={CostApplyList}></Route>
                                            <Route path="/crm/approve/seller-admin-apply/detail/:id" component={SellerAdminApplyDetail}></Route>
                                            <Route path="/crm/approve/seller-admin-apply/history" component={SellerAdminApplyHistory}></Route>
                                            <Route path="/crm/approve/seller-admin-apply/list" component={SellerAdminApplyList}></Route>
                                            <Route path="/crm/approve/win-apply/detail/:id" component={WinApplyDetail}></Route>
                                            <Route path="/crm/approve/win-apply/history" component={WinApplyHistory}></Route>
                                            <Route path="/crm/approve/win-apply/list" component={WinApplyList}></Route>
                                            <Route path="/crm/approve/law-approve/detail/:id" component={LawApproveDetail} ></Route>
                                            <Route path="/crm/approve/law-approve/history" component={LawApproveHistory} ></Route>
                                            <Route path="/crm/approve/law-approve/list" component={LawApproveList} ></Route>
                                            <Route path="/crm/approve/technology-approve/detail/:id" component={TechnologyApproveDetail} ></Route>
                                            <Route path="/crm/approve/technology-approve/history" component={TechnologyApproveHistory} ></Route>
                                            <Route path="/crm/approve/technology-approve/list" component={TechnologyApproveList} ></Route>
                                            <Route path="/crm/approve/work-log-check-manager/list" component={WorkLogCheckManagerList} ></Route>
                                            <Route path="/crm/approve/work-log-check/list" component={WorkLogCheckList} ></Route>
                                            <Route path="/crm/approve/invoice-finance/detail/:id" component={InvoiceApprove} ></Route>
                                            <Route path="/crm/approve/invoice-finance/list" component={InvoiceApproveList} ></Route>
                                            <Route path="/crm/approve/invoice-finance/history" component={InvoiceApproveHistoryList} ></Route>
                                            <Route path="/crm/approve/invoice-enter/detail/:id" component={InvoiceApprove} ></Route>
                                            <Route path="/crm/approve/invoice-enter/list" component={InvoiceApproveList} ></Route>
                                            <Route path="/crm/approve/invoice-enter/history" component={InvoiceApproveHistoryList} ></Route>
                                            <Route path="/crm/approve/go-out-apply/list/seller" component={SellerGoOutApplyList} key={"seller"} ></Route>
                                            <Route path="/crm/approve/go-out-apply/history/seller" component={SellerGoOutApplyHistory} key={"seller"} ></Route>
                                            <Route path="/crm/approve/go-out-apply/detail/seller/:id" component={SellerGoOutApplyDetail} key={"seller"} ></Route>
                                            <Route path="/crm/approve/go-out-apply/list/business" component={SellerGoOutApplyList} key={"business"} ></Route>
                                            <Route path="/crm/approve/go-out-apply/history/business" component={SellerGoOutApplyHistory} key={"business"} ></Route>
                                            <Route path="/crm/approve/go-out-apply/detail/business/:id" component={SellerGoOutApplyDetail} key={"business"} ></Route>
                                            <Route path="/crm/approve/go-out-apply/list" component={GoOutApplyList} ></Route>
                                            <Route path="/crm/approve/go-out-apply/history" component={GoOutApplyHistory} ></Route>
                                            <Route path="/crm/approve/go-out-apply/detail/:id" component={GoOutApplyDetail} ></Route>
                                            <Route path="/crm/approve/contract-file-apply/list" component={ContractFileApplyList} ></Route>
                                            <Route path="/crm/approve/contract-file-apply/history" component={ContractFileApplyHistory} ></Route>
                                            <Route path="/crm/approve/contract-file-apply/detail/:id" component={ContractFileApplyDetail} ></Route>

                                            <Route path="/crm/admin/seller/return-list/:id" component={AdminSellerReturnList}></Route>
                                            <Route path='/crm/admin/diagram-detail' component={DiagramDetail}></Route>
                                            <Route path='/crm/admin/diagram-mark/:id' component={DiagramMark}></Route>
                                            <Route path='/crm/admin/diagram-mark' component={DiagramMark}></Route>
                                            <Route path='/crm/admin/clock-attendance/detail/:id' component={AdminClockAttendanceDetail}></Route>
                                            <Route path='/crm/admin/clock-attendance/list' component={AdminClockAttendanceList}></Route>
                                            <Route path='/crm/admin/track' component={AdminTrackDetail}></Route>
                                            <Route path='/crm/admin/commission/list' component={AdminCommisionList} ></Route>
                                            <Route path='/crm/admin/commission/history' component={AdminCommisionListHistory} ></Route>
                                            <Route path='/crm/admin/commission/detail/:id' component={AdminCommisionCheck} ></Route>
                                            <Route path='/crm/admin/marketCost/list' component={AdminMarketCostList} ></Route>
                                            <Route path='/crm/admin/marketCost/detail/:id' component={AdminMarketCostDetail} ></Route>
                                            <Route path='/crm/admin/return/detail/:id' component={AdminReturnDetail} ></Route>
                                            <Route path="/crm/admin/invoice/detail/:id" component={AdminInvoiceDetail} ></Route>
                                            <Route path='/crm/admin/return/list' component={AdminReturnList} ></Route>
                                            <Route path='/crm/admin/return/:type/list' component={AdminReturnList} ></Route>
                                            <Route path='/crm/admin/return/bind-business/:id' component={ReturnBindBusiness} ></Route>
                                            <Route path="/crm/admin/invoice/list" component={AdminInvoiceList} ></Route>
                                            <Route path="/crm/admin/invoice/:type/list" component={AdminInvoiceList} ></Route>
                                            <Route path="/crm/admin/contract/apply/detail/:id" component={ContractAdminDetail} ></Route>
                                            <Route path="/crm/admin/contract/apply/list" component={ContractAdminList} ></Route>
                                            <Route path="/crm/admin/daily-log/new-detail/:id" component={DailyLogNewDetail} key={"admin"}></Route>
                                            <Route path="/crm/admin/daily-log/list" component={AdminDailyWorkLog}></Route>
                                            <Route path="/crm/admin/salesman/target" component={SellerTarget}></Route>
                                            <Route path="/crm/admin/contract/detail/:id" component={ContractDetail}></Route>
                                            <Route path="/crm/admin/contract/list" component={ContractList}></Route>
                                            <Route path="/crm/admin/work-log/list" component={WorkLogList}></Route>
                                            <Route path="/crm/admin/business/list" component={AdminBusinessList}></Route>
                                            <Route path="/crm/admin/returned-apply/detail/:id" component={ReturnedApplyDetail}></Route>
                                            <Route path="/crm/admin/returned-apply/list/:id" component={WinList} key={"self"}></Route>
                                            <Route path="/crm/admin/returned-apply/list" component={WinList} key={"global"}></Route>
                                            <Route path="/crm/admin/department/detail/:id" component={DepartmentAdd}></Route>
                                            <Route path="/crm/admin/department/add" component={DepartmentAdd}></Route>
                                            <Route path="/crm/admin/department/list" component={DepartmentList}></Route>
                                            <Route path="/crm/admin/salesman/detail/:id" component={SalesmanDetail}></Route>
                                            <Route path="/crm/admin/salesman/edit/:id" component={SalesmanAdd}></Route>
                                            <Route path="/crm/admin/salesman/add" component={SalesmanAdd}></Route>
                                            <Route path="/crm/admin/salesman/list" component={SalesmanList}></Route>
                                            <Route path="/crm/admin/salesman/dashboard/:id" component={Home} key={"sellerSelf"} exact></Route>
                                            <Route path='/crm/admin/tags' component={Tags} ></Route>
                                            <Route path='/crm/admin/weapon/add' component={WeaponAdd} ></Route>
                                            <Route path='/crm/admin/weapon/edit/:id' component={WeaponAdd} ></Route>
                                            <Route path='/crm/admin/weapon/list' component={WeaponList} ></Route>
                                            <Route path='/crm/admin/weapon/detail/:id' component={WeaponDetail} ></Route>
                                            <Route path="/crm/salesman/my-cost/list" component={MyCostList}></Route>
                                            <Route path='/crm/admin/contract/invoice/add/:cid' component={AdminInvoiceAdd} ></Route>
                                            <Route path='/crm/admin/contract/invoice/edit/:id' component={AdminInvoiceAdd} ></Route>

                                            <Route path="/crm/pre-seller/apply/history" component={PreSellerApplyHistory}></Route>
                                            <Route path="/crm/pre-seller/apply/detail/:id" component={PreSellerApplyDetail}></Route>
                                            <Route path="/crm/pre-seller/apply/list" component={PreSellerApplyList}></Route>

                                            <Route path="/crm/client/marketCost-add/:bid" component={MarketCostAdd} ></Route>
                                            <Route path="/crm/client/marketCost-edit/:id" component={MarketCostAdd} ></Route>
                                            <Route path="/crm/client/invoice-edit/:id" component={InvoiceAdd}></Route>
                                            <Route path="/crm/client/invoice-add/:cid" component={InvoiceAdd}></Route>
                                            <Route path="/crm/client/schedule-edit" component={ScheduleAdd}></Route>
                                            <Route path="/crm/client/schedule-add/win/:bid" component={ScheduleAdd}></Route>
                                            <Route path="/crm/client/schedule-add/lose/:bid" component={ScheduleAdd}></Route>
                                            <Route path="/crm/client/schedule-add/:bid" component={ScheduleAdd}></Route>
                                            <Route path="/crm/client/contract-edit/:id" component={ContractAdd}></Route>
                                            <Route path="/crm/client/contract-add/:bid" component={ContractAdd}></Route>
                                            <Route path="/crm/client/business-detail-simple/:id" component={BusinessDetail}></Route>
                                            <Route path="/crm/client/business-detail/:id" component={BusinessDetail}></Route>
                                            <Route path="/crm/client/business-edit/:bid" component={BusinessAdd}></Route>
                                            <Route path="/crm/client/business-add/:cid" component={BusinessAdd}></Route>

                                            <Route path='/crm/salesman/my-diagram-detail' component={MyDiagramDetail}></Route>
                                            <Route path='/crm/salesman/my-go-out/add' component={MyGoOutAdd}></Route>
                                            <Route path='/crm/salesman/my-go-out/detail/:id' component={MyGoOutDetail}></Route>
                                            <Route path='/crm/salesman/my-go-out/list' component={MyGoOutList}></Route>
                                            <Route path='/crm/salesman/my-preseller/list' component={MyPreSellerApplyList}></Route>
                                            <Route path='/crm/salesman/my-preseller/history' component={MyPreSellerApplyHistory}></Route>
                                            <Route path="/crm/salesman/my-marketCost/list" component={MyMarketCostList} ></Route>
                                            <Route path="/crm/salesman/my-marketCost/detail/:id" component={MyMarketCostDetail} ></Route>

                                            <Route path="/crm/salesman/daily/add" component={DailyLogAdd}></Route>
                                            <Route path="/crm/salesman/work-log/new-detail/:id" component={DailyLogNewDetail} key={"salesman"}></Route>
                                            <Route path="/crm/salesman/daily-log" component={DailyLog}></Route>

                                            <Route path="/crm/salesman/my-return/list" component={MyReturnList}></Route>
                                            <Route path="/crm/salesman/my-return/detail/:id" component={MyReturnDetail}></Route>
                                            <Route path="/crm/salesman/log-detail/:id" component={LogDetail}></Route>
                                            <Route path="/crm/salesman/my-log" component={MyLog}></Route>
                                            <Route path="/crm/salesman/list" component={TargetList}></Route>
                                            <Route path="/crm/salesman/my-contract/list" component={MyContractList} ></Route>
                                            <Route path="/crm/salesman/my-contract/detail/:id" component={MyContractDetail} ></Route>
                                            <Route path='/crm/salesman/weapon/list' component={SellerWeaponList} ></Route>
                                            <Route path='/crm/salesman/weapon/detail/:id' component={SellerWeaponDetail} ></Route>
                                            <Route path="/crm/salesman/my-invoice/list" component={MyInvoiceList} ></Route>
                                            <Route path="/crm/salesman/my-invoice/detail/:id" component={MyInvoiceDetail} ></Route>

                                            <Route path="/crm/client-tag/list" component={ClientTagList}></Route>
                                            <Route path="/crm/client/kp/list" component={KpList}></Route>
                                            <Route path="/crm/client/kpdetail/:id" component={KPDetail}></Route>
                                            <Route path="/crm/client/kpedit/:kid" component={KPAdd}></Route>
                                            <Route path="/crm/client/kpadd/:cid" component={KPAdd}></Route>
                                            <Route path="/crm/client/kpadd" component={KPAdd}></Route>
                                            <Route path="/crm/client/detail/:id" component={Detail}></Route>
                                            <Route path="/crm/client/edit/:id" component={Add} key={"edit"}></Route>
                                            <Route path="/crm/client/add" component={Add} key={"add"}></Route>
                                            <Route path="/crm/client/list/:tag" component={ClientList}></Route>
                                            <Route path="/crm/client/list" component={ClientList}></Route>
                                            {/* <Route path='/crm/system/message/send/list' component={MessageSendList} ></Route> */}
                                            <Route path='/crm/system/message/detail/:id' component={MessageDetail} ></Route>
                                            <Route path='/crm/system/message/list' component={MessageList} ></Route>
                                            <Route path='/crm/announcement/detail/:key' component={AnnouncementDetail} ></Route>
                                            <Route path='/crm/announcement/list' component={AnnouncementList} ></Route>
                                            <Route path="/crm/business/list/:type/:stage/:time/:target/:id/:is_important" component={BusinessList}></Route>
                                            {/**商机列表 */}
                                            <Route path="/crm/commercial_opportunity/list" component={CommercialOpportunity}></Route>
                                            <Route path="/crm" component={Home} key="home"></Route>
                                            
                                        </Switch>
                                    </Suspense>
                                </Content>
                                <Footer style={{ textAlign: 'center' }}>谋乐网络科技 ©{moment().year()}</Footer>
                                <div
                                    className="mask-layout"
                                    style={{
                                        display: memory.is_mobile ? (this.state.collapsed ? 'none' : 'block') : 'none'
                                    }}
                                    onClick={() => {
                                        this.setState({
                                            collapsed: true
                                        });
                                    }}
                                >
                                </div>
                            </Layout>
                        </ConfigProvider>
                    )
                }
            </Layout>
        );
    }
}