import React from "react";
import {
    Spin,
    Card,
    Table,
    Descriptions,
    Button,
    message,
    Tag,
    Popconfirm,
    Form,
    Input,
    Modal,
    Tooltip,
    Tabs,
    Row,
    Col,
    DatePicker,
    Switch,
    Upload,
    Radio
} from "antd";
import moment from "moment";
import { LeftOutlined, UploadOutlined, LinkOutlined } from '@ant-design/icons';

import {
    BUSINESSTYPE_OBJ,
    BUSINESSNATURE,
    BUSINESSSCHEDULE_OBJ,
    BUSINESSQUESTION_OBJ,
    BUSINESSLEVEL,
    RECOMMEND_TYPE_OBJ,
    ROLES,
    CONTRACT_HEAD_TYPE_ALL_OBJ,
    CONTRACT_SUB_TYPE_OBJ,
    MARKETCOST_FORM_OBJ,
    MARKETCOST_TYPE_OBJ,
    EXPIRATION_DAYS,
    SELLERCONFIG,
    AUTH,
    BUSINESSLEVELOBJ,
    BUSINESSNATURE_OBJ,
    PROJECT_ACTION_NAME,
    PROCUREMENT_CONTRACT,
    SELLER_CONTRACT,
    BUSINESS_BELONG, // 项目所属
    FINISH_STATUS
} from "../../../../../utils/constant";
import { crmApi, reqUserList } from "../../../../../api";
import tools from "../../../../../utils/tools";
import { Link } from "react-router-dom";
import memory from "../../../../../utils/memory";
import LinkButton from "../../../components/link-button";
import NumericInput from "../../../../../components/numeric-input";
import DownloadLink from "../../../../../components/download-link";
import MarketStateTag from '../../../../../components/market-state-tag'
import storage from "../../../../../utils/storage";
import "./index.less"
const TextArea=Input.TextArea
const {
    reqBusinessDetail,
    reqContractSelf,
    reqContractList,
    reqBusinessApply,
    reqBusinessDelWinApply,
    reqWeaponList,
    reqContactsCollection,
    reqLastInvoice,
    reqReturnList,
    reqMarketingBusinessList,
    reqPreSellerCheck,
    reqPreSellerList,
    reqBusinessEdit,
    reqBusinessProjectEdit,
    reqBusinessFinished,
    reqBusinessRemarksEdit,
    reqOperationLogList,
    reqNewDailyActionList,
    reqCheckProgress
} = crmApi;
const {
    ADMIN,
    CRMADMIN,
    HRBP,
    MANAGER
} = ROLES;

const messageColumns = [
    {
        title: '添加人',
        dataIndex: 'creator',
        key: 'creator',
        render: (text) => (text?.dd_info?.name || "-")
    },
    {
        title: '添加时间',
        dataIndex: 'create_at',
        key: 'create_at',
        render: (text) => (text ? moment(text).format("YYYY/MM/DD HH:mm:ss") : "-")
    },
    {
        title: '描述',
        dataIndex: 'content',
        key: 'content',
        render: text => text || "-"
    },
];

const operationLogColumns = [
    {
        title: '操作人',
        dataIndex: 'operator',
        key: 'operator',
    },
    {
        title: '操作时间',
        dataIndex: 'operation_at',
        key: 'operation_at',
        render: (text) => (text ? moment(text).format("YYYY/MM/DD HH:mm:ss") : "-")
    },
    {
        title: '类型',
        dataIndex: 'operation_type',
        key: 'operation_type',
        render: text => text || "-"
    },
];

class ReturnCreateForm extends React.Component {
    state = {
        number: null,
        collection_details: [],
        tab: 0,
        loading: false,
    }
    formRef = React.createRef();

    handleNumberChange = (value, inputChange) => {
        let { collection_details, tab } = this.state;
        const { type, contract } = this.props;
        if (inputChange !== "blur") {
            if (value) {
                let arr = [];
                if (collection_details.length > value) {
                    return;
                } else if (collection_details.length < value) {
                    arr = collection_details;
                    for (let i = collection_details.length; i < value; i++) {
                        arr.push({ batch: i + 1 });
                    }
                } else {
                    arr = collection_details;
                }
                if (Number(tab) + 1 > value) {
                    this.handleTabChange(0);
                }
                this.setState({
                    number: value,
                    collection_details: arr,
                })
            }
        } else if (value !== this.state.number) {
            if (type === "edit") {
                if (!value || value < contract.is_collection_times) {
                    value = contract.is_collection_times;
                    setTimeout(() => {
                        this.formRef.current.setFieldsValue({ times: value });
                    }, 100);
                }
            } else if (!value) {
                value = 1;
                setTimeout(() => {
                    this.formRef.current.setFieldsValue({ times: value });
                }, 100);
            }
            let arr = [];
            if (collection_details.length > value) {
                collection_details.splice(value, collection_details.length - value);
                arr = collection_details;
            } else if (collection_details.length < value) {
                arr = collection_details;
                for (let i = collection_details.length; i < value; i++) {
                    arr.push({ batch: i + 1 });
                }
            } else {
                arr = collection_details;
            }
            if (Number(tab) + 1 > value) {
                this.handleTabChange(0);
            }
            this.setState({
                number: value,
                collection_details: arr,
            })
        }
    }

    handleAmountChange = (value) => {
        let { collection_details, tab } = this.state;
        collection_details[tab].amount = Number(value);
        this.setState({
            collection_details
        })
    }

    handleTimeChange = (value) => {
        let { collection_details, tab } = this.state;
        collection_details[tab].collection_at = moment(value)._d;
        this.setState({
            collection_details
        })
    }

    handleTabChange = (value) => {
        let { collection_details } = this.state;
        const { setFieldsValue } = this.formRef.current;
        if (value != null) {
            setFieldsValue({
                ["amount" + value]: collection_details[value].amount || undefined,
                ["collection_at" + value]: collection_details[value].collection_at ? moment(collection_details[value].collection_at) : undefined,
            });
        }
        this.setState({
            tab: value != null ? value : '0',
        })
    }

    handleCreate = () => {
        let { collection_details, number } = this.state;
        let { contract, onCreate, type } = this.props;
        let collection = collection_details.find((o) => {
            if (o) {
                if (o.amount == null) {
                    return true;
                }
                if (type !== "edit" && o.collection_at == null) {
                    return true;
                }
            }
            return false;
        })
        if (number == null || number == 0) {
            message.error('请填写回款次数');
            return;
        }
        if (collection) {
            message.error('有回款明细未填写，无法提交');
            this.handleTabChange(collection.batch - 1);
            return;
        }
        let amount = 0;
        for (let i = 0; i < collection_details.length; i++) {
            let item = collection_details[i];
            amount += Number(item.amount);
        }
        if (amount != contract.contract_amount) {
            message.error('回款总金额与合同金额不一致，无法提交');
            return;
        }
        Modal.confirm({
            content: type === "edit" ? "确认是否更改回款信息?" : '回款信息填写完非特殊情况不能修改，确认是否提交？',
            okText: '确定',
            cancelText: '取消',
            onOk: async () => {
                this.setState({
                    loading: true,
                })
                let params = {
                    id: contract._id,
                    collection_times: number,
                    collection_details,
                }
                const response = await reqContactsCollection(params);
                if (response && response.data) {
                    message.success('回款信息添加成功');
                    onCreate();
                }
                this.setState({
                    loading: false,
                })
            }
        })
    }

    componentWillReceiveProps(newProps) {
        if (newProps.visible === true && newProps.visible !== this.props.visible && this.props.type === "edit") {
            this.setState({
                number: this.props.contract.collection_times,
                collection_details: JSON.parse(JSON.stringify(this.props.contract.collection_details)),
                tab: this.state.tab >= this.props.contract.collection_details.length ? 0 : this.state.tab,
            }, () => {
                this.formRef.current.resetFields();
            });
        }
    }

    render() {
        const { visible, contract, onCancel, type } = this.props;
        return (
            <Modal
                open={visible}
                title="回款批次信息"
                okText="提交"
                onCancel={onCancel}
                onOk={this.handleCreate}
                confirmLoading={this.state.loading}
            >
                <Form layout="vertical" ref={this.formRef}>
                    <Form.Item
                        label="回款次数"
                        name="times"
                        initialValue={this.state.number || undefined}
                        rules={[
                            {
                                required: true,
                                message: '请填写回款次数',
                            }
                        ]}
                    >
                        <NumericInput is_int={true} placeholder='请填写回款次数' max_num={100} onChange={this.handleNumberChange} />
                    </Form.Item>
                    {
                        this.state.number ?
                            <Form.Item label='回款明细' required>
                                <Tabs
                                    size={"small"}
                                    activeKey={this.state.tab ? this.state.tab.toString() : '0'}
                                    onChange={this.handleTabChange}
                                >
                                    {this.state.collection_details.map((value, index) => (
                                        <Tabs.TabPane tab={`第${index + 1}次`} key={index}>
                                            <Row gutter={16}>
                                                <Col span={12}>
                                                    <Form.Item
                                                        label="回款金额"
                                                        name={'amount' + index}
                                                        initialValue={this.state.collection_details[this.state.tab] && this.state.collection_details[this.state.tab].amount ? this.state.collection_details[this.state.tab].amount : undefined}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: '请填写回款金额',
                                                            }
                                                        ]}
                                                        style={{ marginBottom: 0 }}
                                                    >
                                                        <NumericInput placeholder='请填写回款金额' onChange={this.handleAmountChange} disabled={type === "edit" && (index < contract.is_collection_times && index>0)} />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item
                                                        label="回款时间"
                                                        name={'collection_at' + index}
                                                        initialValue={this.state.collection_details[this.state.tab] && this.state.collection_details[this.state.tab].collection_at ? moment(this.state.collection_details[this.state.tab].collection_at) : undefined}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: '请填写回款时间',
                                                            }
                                                        ]}
                                                        style={{ marginBottom: 0 }}
                                                    >
                                                        <DatePicker placeholder='请填写回款时间' style={{ width: '100%' }} onChange={this.handleTimeChange} disabled={type === "edit" && index < contract.is_collection_times} />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Tabs.TabPane>
                                    ))}
                                </Tabs>
                            </Form.Item>
                            :
                            null
                    }
                </Form>
            </Modal>
        );
    }
}

const CollectionCreateForm = ({visible, onCancel, onCreate}) => {
    const [form] = Form.useForm();
    return (
        <Modal
            open={visible}
            title="确认回款已完结吗？"
            okText="确认"
            onCancel={onCancel}
            onOk={()=>{
                form
                .validateFields()
                .then((values) => {
                    form.resetFields();
                    onCreate(values);
                })
                .catch((info) => {
                });
            }}
        >
            <Form
                form={form}
                layout='vertical'
            >
                <Form.Item
                    label="完结类型"
                    name="finishType"
                    initialValue={1}
                >
                    <Radio.Group 
                        buttonStyle="solid" 
                    >
                        <Radio.Button value={1}>正常回款</Radio.Button>
                        <Radio.Button value={2}>终止合作</Radio.Button>
                        <Radio.Button value={3}>坏账</Radio.Button>
                        <Radio.Button value={4}>其他</Radio.Button>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    label="备注"
                    name="finish_remarks"
                >
                    <TextArea />
                </Form.Item>
            </Form>
        </Modal>
    );
};

class MessageCollectionCreateForm extends React.Component {
    formRef = React.createRef();
    normFile = e => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    handlePreview = async (file) => {
        tools.downloadFile(this.props.history, file, `${memory.user.dd_info.name} ${moment().format("YYYY/MM/DD")}`);
    }

    /*上传相关*/
    handleChange = info => {
        let fileList = [...info.fileList];
        if (info.file.status === 'uploading') {
            this.setState({
                fileLoading: true,
            })
        }
        if (info.file.status === 'done') {
            this.setState({
                fileLoading: false,
            })
        }
        // 2. Read from response and show file link
        fileList = fileList.map(file => {
            if (file.response) {
                // Component will show file.url as link
                file.url = file.response.url;
            }
            file = {
                uid: file.uid,
                name: file.name,
                status: file.status,
                url: file.url
            };
            return file;
        });

    };

    render() {
        const { visible, onCancel, onCreate } = this.props;

        let headers = {};
        const auth = storage.getStore(AUTH);
        if (auth) {
            headers = {
                authorization: 'Bearer ' + auth
            };
        }
        const props = {
            action: '/api/files/ue?action=uploadfile',
            headers: headers,
            onChange: this.handleChange,
            multiple: true,
            onPreview: this.handlePreview,
        };
        return (
            <Modal
                visible={visible}
                title={"添加备注"}
                okText="确定"
                onCancel={onCancel}
                onOk={onCreate}
            >
                <Form layout="vertical" ref={this.formRef}>
                    <Form.Item
                        label={"消息"}
                        name="content"
                        rules={[
                            {
                                required: true,
                                message: '请填写项目备注',
                            },
                        ]}
                    >
                        <Input.TextArea rows={4} placeholder={'请填写项目备注'} />
                    </Form.Item>
                    <Form.Item
                        label={"附件"}
                        name="attachments"
                        valuePropName="fileList"
                        getValueFromEvent={this.normFile}
                    >
                        <Upload {...props} >
                            <Button>
                                <UploadOutlined /> 上传
                            </Button>
                        </Upload>
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
};

export default class BusinessDetail extends React.Component {
    state = {
        visible: false,
        loading: true,
        detail: {},
        schedule: [],
        contract: [],
        cooperator: [], //合作者

        returnVisible: false,
        returnFormType: '',
        returnFormContract: null,

        returnedList: [],
        marketCostList: [],
        submitLoading: false,
        popconfirmVisible: false,
        messageVisible: false,
        operatonLogList: [],
        finishOpen:false,
        contractData:[],
            quotationData:[],
            winData:[]
        
    };
    messageFormRef = React.createRef();
    id = this.props.id || this.props.match.params.id;
    isUnlockApprover = !!memory.user.roles.find(o => o.type == ROLES.UNLOCK);
    isManagerAdmin = !!memory.user.roles.find(o => o.type === ROLES.MANAGER || o.type === ROLES.PRESELLER_ADMIN);
    isPreSellerAdmin = !!memory.user.roles.find(o => o.type === ROLES.PRESELLER_ADMIN);
    isPreSeller = !!memory.user.roles.find(o => o.type === ROLES.PRESELLER);
    isProjectContractAdmin = !!memory.user.roles.find(o => o.type === ROLES.PROJECT_CONSTRACT);
    isProjectFinished = !!memory.user.roles.find(o => o.type === ROLES.PROJECT_FINISHED);
    isCRMAdmin = !!memory.user.roles.find(o => o.type === ROLES.CRMADMIN);
    isSimple = this.props.history.location.pathname.includes("business-detail-simple"); //是否是简略版详情
    isVENDORADMIN = memory.user.roles.some(o => o.type === ROLES.VENDOR_ADMIN); // 供应商管理
    isFINANCE = memory.user.roles.some(o => o.type === ROLES.FINANCE); // 财务总监
    admin_action = false;
    preSellerList = []
    columns = [
        {
            title: '姓名',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '贡献度',
            dataIndex: 'proportion',
            key: 'proportion',
            render: (data) => data + '%'
        },
    ];
    checkColumns=[
        {
            title:"事项",
            dataIndex:"name",
            key:'name',
            render:(_,record)=>{
                const recordKeys={
                    "contract":'合同审批',
                    "quotation":'报价单审批',
                    "win":'赢单审批'
                }
                return <div>{recordKeys[record.type]}</div>
            }
        },
        {
            title:"当前状态",
            dataIndex:"status",
            key:'status',
            render:(_,record)=>{
                const recordKeys={
                    "-1":<Tag color="error">未通过</Tag>,
                    "0":<Tag color="processing">审批中</Tag>,
                    "1":<Tag color="success">已通过</Tag>
                }
                return <div>{recordKeys[record.status]}</div>
            }
        },{
            title:"审批人",
            dataIndex:"examiner",
            key:'examiner',
            render:(_,record)=>{
                let examiners=record.examiner.map(item=>item.name||'-').join(",")
                let stage=record.stage==2?'进行 合同交付部门':(record.stage==3?'进行 法务':'')
                if(record.type=='contract'){
                    switch(record.stage){
                        case 4: return <div>等待 {examiners} 进行合同下载审批</div>;
                        case 5: return <div>等待 {examiners} 进行合同上传</div>;
                        case 6: return <div>等待 {examiners} 进行合同确认</div>;
                    }
                }
                
                if(record.status==0){
                    return <div>正在等待{examiners} {record.stage?stage:""}审批</div>
                }else if(record.status==1){
                    return <div>已由 {examiners} 审批通过</div>
                }else if(record.status==-1){
                    return <div>{examiners} 审批不通过</div>
                }
                
            }
        },{
            title:"备注",
            dataIndex:"examined_content",
            key:'examined_content',
            render:(_,record)=>{
                return record.examined_content || '-'
            }
        }
    ]
    scheduleColumns = [
        {
            title: '时间',
            dataIndex: 'time',
            key: 'time',
        },
        {
            title: '进度内容',
            dataIndex: 'content',
            key: 'content',
        },
        {
            title: '操作人',
            dataIndex: 'update_by',
            key: 'update_by',
            render: text => text?.dd_info?.name || "-",
        },
        {
            title: '预计毛利',
            dataIndex: 'estimate_gross_profit',
            key: 'estimate_gross_profit',
        },
        {
            title: '操作',
            key: 'action',
            render: (text, record) => {
                if (record.bstate != null && record.bstate === 1) return (
                    <Popconfirm
                        title="确定撤回该赢单申请吗?"
                        onConfirm={this.delWinApply}
                        okText="确定"
                        cancelText="取消"
                    >
                        <Link>撤回</Link>
                    </Popconfirm>
                );
                return '-';
            },
        }
    ];
    returnedColumns = [
        {
            title: '金额',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: '回款占比',
            dataIndex: 'proportion',
            key: 'proportion',
            render: (text, record) => {
                if (text) return text;
                return record.proportion_amount != null && this.state.detail && this.state.detail.contract_amount ? (record.proportion_amount / this.state.detail.contract_amount * 100).toFixed(2) + '%' : '-'
            }
        },
        {
            title: '回款时间',
            dataIndex: 'return_at',
            key: 'return_at',
        },
        {
            title: '记录添加人',
            dataIndex: 'creator',
            key: 'creator',
        },
        {
            title: '记录添加时间',
            dataIndex: 'create_at',
            key: 'create_at',
        },
        {
            title: '备注',
            dataIndex: 'remark',
            key: 'remark',
        },
    ];
    sellerContractColumns = [
        {
            title: "合同名称",
            dataIndex: "contract_name",
            key: "contract_name",
            render: text => text || "-",
        },
        {
            title: "合同编号",
            dataIndex: "contract_number",
            key: "contract_number",
            render: text => text || "-",
        },
        {
            title: "合同金额",
            dataIndex: "contract_amount",
            key: "contract_amount",
            render: text => text ? tools.formatNumber(text) + "元" : "0元",
        },
        {
            title: "合同类型",
            dataIndex: "contract_type",
            key: "contract_type",
            render: text => {
                if (text) {
                    if (SELLER_CONTRACT.some(o => o === text)) {
                        return <Tag color="#ff0000">{CONTRACT_HEAD_TYPE_ALL_OBJ[text]}</Tag>
                    }
                    if (PROCUREMENT_CONTRACT.some(o => o === text)) {
                        return <Tag color="#008000">{CONTRACT_HEAD_TYPE_ALL_OBJ[text]}</Tag>
                    }
                    return <Tag color="#0000ff">{CONTRACT_HEAD_TYPE_ALL_OBJ[text]}</Tag>
                }
                return "-"
            },
        },
        {
            title: "合同性质",
            dataIndex: "contract_stype",
            key: "contract_stype",
            render: text => text ? CONTRACT_SUB_TYPE_OBJ[text]?.text || "-" : "-",
        },
        {
            title: "合同签署日期",
            dataIndex: "sign_date",
            key: "sign_date",
            render: text => text ? moment(text).format("YYYY-MM-DD") : "-",
        },
        {
            title: "服务起止日期",
            dataIndex: "contract_date",
            key: "contract_date",
            render: (text, record) => `${record.contract_startdate ? moment(record.contract_startdate).format("YYYY/MM/DD") : "/"} - ${record.contract_enddate ? moment(record.contract_enddate).format("YYYY/MM/DD") : "/"}`,
        },
        {
            title: "备注",
            dataIndex: "remarks",
            key: "remarks",
            render: text => text || "-",
        },
        {
            title: "操作",
            dataIndex: "option",
            key: "option",
            render: (text, record) => {
                const { detail } = this.state;
                if (detail?.creator?.id === memory.user._id || (detail?.project_managers?.length && detail.project_managers.findIndex(o => o === memory.user._id))) {
                    if (SELLER_CONTRACT.some(o => record.contract_type === o)) {
                        if (record.collection_times == null) {
                            return (
                                <div>
                                    <a
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            this.setState({
                                                returnVisible: true,
                                                returnFormType: 'add',
                                                returnFormContract: record
                                            });
                                        }}
                                    >
                                        确认回款批次
                                    </a>
                                </div>
                            )
                        } else {
                            return (
                                <div>
                                    <a onClick={() => {
                                        this.setState({
                                            returnFormType: 'edit',
                                            returnFormContract: record
                                        });
                                        this.handleReturnEdit();
                                    }}>
                                        修改回款批次
                                    </a>
                                    <span>&nbsp;|&nbsp;</span>
                                    {
                                        record.is_invoice == 1 ?
                                            <a disabled>
                                                发票申请审批中
                                            </a>
                                            :
                                            (
                                                record.is_invoice == 2 && record.is_collection_times == record.collection_times ?
                                                    <a disabled>
                                                        发票已申请完毕
                                                    </a>
                                                    :
                                                    <a onClick={() => this.handleInvoiceApply(record)}>
                                                        发票申请
                                                    </a>
                                            )
                                    }
                                </div>
                            )
                        }
                    }
                }
                return "-";
            }
        }
    ];
    procurementContractColumns = [
        {
            title: "合同名称",
            dataIndex: "contract_name",
            key: "contract_name",
            render: text => text || "-",
        },
        {
            title: "合同编号",
            dataIndex: "contract_number",
            key: "contract_number",
            render: text => text || "-",
        },
        {
            title: "合同金额",
            dataIndex: "contract_amount",
            key: "contract_amount",
            render: text => text ? tools.formatNumber(text) + "元" : "0元",
        },
        {
            title: "合同类型",
            dataIndex: "contract_type",
            key: "contract_type",
            render: text => {
                if (text) {
                    if (SELLER_CONTRACT.some(o => o === text)) {
                        return <Tag color="#ff0000">{CONTRACT_HEAD_TYPE_ALL_OBJ[text]}</Tag>
                    }
                    if (PROCUREMENT_CONTRACT.some(o => o === text)) {
                        return <Tag color="#008000">{CONTRACT_HEAD_TYPE_ALL_OBJ[text]}</Tag>
                    }
                    return <Tag color="#0000ff">{CONTRACT_HEAD_TYPE_ALL_OBJ[text]}</Tag>
                }
                return "-"
            },
        },
        {
            title: "合同性质",
            dataIndex: "contract_stype",
            key: "contract_stype",
            render: text => text ? CONTRACT_SUB_TYPE_OBJ[text]?.text || "-" : "-",
        },
        {
            title: "已付款",
            dataIndex: "paid",
            key: "paid",
            render: (text, record) => {
                if (PROCUREMENT_CONTRACT.includes(record.contract_type)) {
                    return text ? tools.formatNumber(text) + "元" : "0元";
                }
                return "-";
            },
        },
        {
            title: "未付款",
            dataIndex: "no_paid",
            key: "no_paid",
            render: (text, record) => {
                if (PROCUREMENT_CONTRACT.includes(record.contract_type)) {
                    return text ? tools.formatNumber(text) + "元" : "0元";
                }
                return "-";
            },
        },
        {
            title: "供应商",
            dataIndex: "vendor",
            key: "vendor",
            render: text => text?.name || "-",
        },
        {
            title: "合同签署日期",
            dataIndex: "sign_date",
            key: "sign_date",
            render: text => text ? moment(text).format("YYYY-MM-DD") : "-",
        },
        {
            title: "服务起止日期",
            dataIndex: "contract_date",
            key: "contract_date",
            render: (text, record) => `${record.contract_startdate ? moment(record.contract_startdate).format("YYYY/MM/DD") : "/"} - ${record.contract_enddate ? moment(record.contract_enddate).format("YYYY/MM/DD") : "/"}`,
        },
        {
            title: "备注",
            dataIndex: "remarks",
            key: "remarks",
            render: text => text || "-",
        },
    ];
    otherContractColumns = [
        {
            title: "合同名称",
            dataIndex: "contract_name",
            key: "contract_name",
            render: text => text || "-",
        },
        {
            title: "合同编号",
            dataIndex: "contract_number",
            key: "contract_number",
            render: text => text || "-",
        },
        {
            title: "合同金额",
            dataIndex: "contract_amount",
            key: "contract_amount",
            render: text => text ? tools.formatNumber(text) + "元" : "0元",
        },
        {
            title: "合同类型",
            dataIndex: "contract_type",
            key: "contract_type",
            render: text => {
                if (text) {
                    if (SELLER_CONTRACT.some(o => o === text)) {
                        return <Tag color="#ff0000">{CONTRACT_HEAD_TYPE_ALL_OBJ[text]}</Tag>
                    }
                    if (PROCUREMENT_CONTRACT.some(o => o === text)) {
                        return <Tag color="#008000">{CONTRACT_HEAD_TYPE_ALL_OBJ[text]}</Tag>
                    }
                    return <Tag color="#0000ff">{CONTRACT_HEAD_TYPE_ALL_OBJ[text]}</Tag>
                }
                return "-"
            },
        },
        {
            title: "合同性质",
            dataIndex: "contract_stype",
            key: "contract_stype",
            render: text => text ? CONTRACT_SUB_TYPE_OBJ[text]?.text || "-" : "-",
        },
        {
            title: "合同签署日期",
            dataIndex: "sign_date",
            key: "sign_date",
            render: text => text ? moment(text).format("YYYY-MM-DD") : "-",
        },
        {
            title: "服务起止日期",
            dataIndex: "contract_date",
            key: "contract_date",
            render: (text, record) => `${record.contract_startdate ? moment(record.contract_startdate).format("YYYY/MM/DD") : "/"} - ${record.contract_enddate ? moment(record.contract_enddate).format("YYYY/MM/DD") : "/"}`,
        },
        {
            title: "备注",
            dataIndex: "remarks",
            key: "remarks",
            render: text => text || "-",
        },
    ];
    simpleContractColumns = [
        {
            title: "合同名称",
            dataIndex: "contract_name",
            key: "contract_name",
        },
        {
            title: "合同编号",
            dataIndex: "contract_number",
            key: "contract_number",
        },
    ];

    marketCostColumns = [
        {
            title: '申请金额',
            dataIndex: 'requestAmount',
            key: 'requestAmount'
        },
        {
            title: '额外费用类型',
            dataIndex: 'market_nature',
            key: 'market_nature'
        },
        {
            title: '额外费用形式',
            dataIndex: 'market_form',
            key: 'market_form'
        },
        {
            title:"费用比例",
            dataIndex:"proportion",
            key:'proportion',
        },
        
        {
            title: '期望执行时间',
            dataIndex: 'time',
            key: 'time'
        },
        {
            title: '申请/操作人',
            dataIndex: 'creator',
            ket: 'creator',
            render: text => text || "-"
        },
        {
            title: '申请时间',
            dataIndex: 'create_at',
            ket: 'create_at',
        },
        {
            title: '审批状态',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => {
                return (
                    <MarketStateTag is_history={record.is_history} is_examined={record.is_examined} stage={record.stage} />
                )
            }
        },
        {
            title: '操作',
            dataIndex: 'option',
            key: 'option',
            render: (_, record, index) => {
                return <Link to={`/crm/salesman/my-marketCost/detail/${record.id}`} target={this.props.id ? "_blank" : undefined}>详情</Link>
            }
        }
    ];

    PROJECT_CONTENTS = {
        "project_name": {
            text: "项目名称",
            format: value => value || "无",
        },
        "business_type": {
            text: "项目类型",
            format: async (value) => {
                try {
                    const business_type_arr = JSON.parse(value);
                    if (business_type_arr?.length) {
                        let arr = [];
                        for (let i = 0; i < business_type_arr.length; i++) {
                            const item = business_type_arr[i];
                            if (tools.isNumber(item) === false) {
                                if (this.weaponListObj) {
                                    arr.push(item != null ? this.weaponListObj[item] : "无");
                                } else {
                                    await this.getWeaponList({ node: 9999 });
                                    arr.push(item != null ? this.weaponListObj[item] : "无");
                                }
                            }
                        }
                        return arr.join("、");
                    }
                    return "-";
                } catch (e) { }
                if (tools.isNumber(value) === false) {
                    if (this.weaponListObj) {
                        return value != null ? this.weaponListObj[value] : "无";
                    } else {
                        await this.getWeaponList({ node: 9999 });
                        return value != null ? this.weaponListObj[value] : "无";
                    }
                }
                return value != null ? BUSINESSTYPE_OBJ[value] : "无";
            },
        },
        "type": {
            text: "项目等级",
            format: value => value != null ? BUSINESSLEVELOBJ[value] : "无",
        },
        "customer_name": {
            text: "客户名称",
            format: value => value || "无",
        },
        "project_type": {
            text: "项目性质",
            format: value => value != null ? BUSINESSNATURE_OBJ[value] : "无",
        },
        "upstream_cost": {
            text: "上游成本",
            format: value => (
                value == null || value == "null" ? "待审批" : (
                    value == -1 ? "审批中" : tools.formatNumber(value) + "元"
                )
            ),
        },
        "upstream_cost_reason": {
            text: "上游成本与采购合同金额不一致原因",
            format: value => value || "无",
        },
        "service_cost": {
            text: "服务成本",
            format: value => (
                value == null || value == "null" ? "待审批" : (
                    value == -1 ? "审批中" : tools.formatNumber(value) + "元"
                )
            ),
        },
        "average_cost": {
            text: "其他成本",
            format: value => (
                value == null || value == "null" ? "待审批" : (
                    value == -1 ? "审批中" : tools.formatNumber(value) + "元"
                )
            ),
        },
        "pre_cost": {
            text: "项目经理成本",
            format: value => (
                value == null || value == "null" ? "待审批" : (
                    value == -1 ? "审批中" : tools.formatNumber(value) + "元"
                )
            ),
        },
        "marketing_cost": {
            text: "额外费用",
            format: value => (
                value == null || value == "null" ? "待审批" : (
                    value == -1 ? "审批中" : tools.formatNumber(value) + "元"
                )
            ),
        },
        "return_to_factory": {
            text: "公司均摊成本",
            format: value => (
                value == null || value == "null" ? "待审批" : (
                    value == -1 ? "审批中" : tools.formatNumber(value) + "元"
                )
            ),
        },
        "upstream_tax": {
            text: "上游税费",
            format: value => (
                value == null || value == "null" ? "待审批" : (
                    value == -1 ? "审批中" : tools.formatNumber(value) + "元"
                )
            ),
        },
        "project_number": {
            text: "合同编号",
            format: value => value || "无",
        },
        "business_number": {
            text: "项目编号",
            format: value => value || "无",
        },
        "product_contract_amount": {
            text: "产品合同金额",
            format: value => (
                value == null || value == "null" ? "待审批" : (
                    value == -1 ? "审批中" : tools.formatNumber(value) + "元"
                )
            ),
        },
        "service_contract_amount": {
            text: "服务合同金额",
            format: value => (
                value == null || value == "null" ? "待审批" : (
                    value == -1 ? "审批中" : tools.formatNumber(value) + "元"
                )
            ),
        },
        "contract_amount": {
            text: "合同金额",
            format: value => value != null ? tools.formatNumber(value) + "元" : "无",
        },
        "project_tax": {
            text: "项目税费",
            format: value => (
                value == null || value == "null" ? "待审批" : (
                    value == -1 ? "审批中" : tools.formatNumber(value) + "元"
                )
            ),
        },
        "gross_profit": {
            text: "毛利",
            format: value => (
                value == null || value == "null" ? "待审批" : (
                    value == -1 ? "审批中" : tools.formatNumber(value) + "元"
                )
            ),
        },
        "expected_signing_date": {
            text: "预计签约日期",
            format: value => value != null ? moment(value).format("YYYY-MM-DD HH:mm:ss") : "无",
        },
        "status": {
            text: "项目状态",
            format: value => {
                const status = {
                    0: "正常",
                    1: "赢单",
                    2: "赢单已回款",
                    3: "赢单全部回款",
                    "-1": "输单",
                }
                return value != null ? status[value] : "无"
            },
        },
        "stage": {
            text: "项目阶段",
            format: value => value != null ? BUSINESSSCHEDULE_OBJ[value] : "无",
        },
        "specifications": {
            text: "是否是标准项目",
            format: value => value == 1 ? "是" : (value == 2 ? "否" : "无"),
        },
        "schedule": {
            text: "项目进度",
            format: value => {
                const schedule = JSON.parse(value);
                return schedule?.type ? BUSINESSSCHEDULE_OBJ[schedule.type] : "无";
            },
        },
        "bstate": {
            text: "赢单状态",
            format: value => {
                const obj = {
                    1: "已提交赢单申请",
                    2: "赢单审批结束",
                    3: "赢单审批被驳回",
                }
                return value != null ? obj[value] : "无";
            },
        },
        "paid": {
            text: "已回款金额",
            format: value => value != null ? tools.formatNumber(value) + "无" : "无",
        },
        "no_paid": {
            text: "待回款金额",
            format: value => value != null ? tools.formatNumber(value) + "无" : "无",
        },
        "project_managers": {
            text: "项目经理",
            format: async (value) => {
                if (this.projectManagerObj) {
                    return value != null ? this.projectManagerObj[value]?.name : "无";
                } else {
                    await this.getProjectManagerObj({
                        page: 1,
                        node: 9999,
                    });
                    return value != null ? this.projectManagerObj[value]?.name : "无";
                }
            },
        },
        "project_description": {
            text: "项目描述",
            format: value => value || "无",
        },
        "expiration_time": {
            text: "项目剩余时间",
            format: value => value != null ? moment(value).format("YYYY-MM-DD HH:mm:ss") : "无",
        },
        "transfer_history": {
            text: "转移历史",
            format: async (value) => {
                const obj = JSON.parse(value);
                if (obj) {
                    if (this.projectManagerObj) {
                        return `执行人：${this.projectManagerObj[obj.executor]?.name || "-"}，转移时间：${moment(obj.time).format("YYYY-MM-DD HH:mm:ss")}，转移前销售：${this.projectManagerObj[obj.from]?.name || "-"}，转移后销售：${this.projectManagerObj[obj.to]?.name || "-"}`;
                    } else {
                        await this.getProjectManagerObj({
                            page: 1,
                            node: 9999,
                        });
                        return `执行人：${this.projectManagerObj[obj.executor]?.name || "-"}，转移时间：${moment(obj.time).format("YYYY-MM-DD HH:mm:ss")}，转移前销售：${this.projectManagerObj[obj.from]?.name || "-"}，转移后销售：${this.projectManagerObj[obj.to]?.name || "-"}`;
                    }
                }
                return "无";
            },
        },
        "closed": {
            text: "是否关闭",
            format: value => value ? "是" : "否",
        },
        "closed_at": {
            text: "关闭时间",
            format: value => value != null ? moment(value).format("YYYY-MM-DD HH:mm:ss") : "无",
        },
        "remarks": {
            text: "备注",
            format: value => {
                const remark = JSON.parse(value);
                if (remark) {
                    return (
                        <span>
                            备注内容：
                            {remark.content || "-"}，
                            附件：
                            {remark.attachments?.length ? <a onClick={() => this.getFileModal(remark.attachments)}>点击查看</a> : "无"}
                        </span>
                    );
                }
            },
        },
    };

    getContractExpanded = (record) => {
        let files = [];
        if (record.moa_contract_file && record.moa_contract_file.length && record.moa_contract_file[0].name) {
            files = record.moa_contract_file;
        } else if (record.contract_file && record.contract_file.length && record.contract_file[0].name) {
            files = record.contract_file;
        }
        return (
            <div style={{ width: "80vw" }}>
                <Descriptions
                    title="合同说明"
                    style={{
                        marginBottom: 24,
                    }}
                    column={1}
                >
                    <Descriptions.Item>
                        <div style={{ whiteSpace: 'pre-wrap' }}>
                            {record.contract_survey || '-'}
                        </div>
                    </Descriptions.Item>
                </Descriptions>
                {
                    record.moa_contract_file_single && record.moa_contract_file_double && (record.moa_contract_file_single.length || record.moa_contract_file_double.length) || (!record.moa_contract_file || !record.moa_contract_file.length) || !files.length ?
                        <>
                            <DownloadLink title={'合同单章文件'} file={record.moa_contract_file_single} isContract={true} contract={record._id} />
                            <DownloadLink title={'合同双章文件'} file={record.moa_contract_file_double} isContract={true} contract={record._id} />
                        </>
                        :
                        <DownloadLink title={'合同扫描文件'} file={record.moa_contract_file} isContract={true} contract={record._id} />
                }
            </div>
        )
    }

    handleContractExpanded = (record) => {
        let files = [];
        if (record.moa_contract_file && record.moa_contract_file.length && record.moa_contract_file[0].name) {
            files = record.moa_contract_file;
        } else if (record.contract_file && record.contract_file.length && record.contract_file[0].name) {
            files = record.contract_file;
        }
        return (
            <div style={{ width: "80vw" }}>
                {
                    record.moa_contract_file_single && record.moa_contract_file_double && (record.moa_contract_file_single.length || record.moa_contract_file_double.length) || (!record.moa_contract_file || !record.moa_contract_file.length) || !files.length ?
                        <>
                            <DownloadLink title={'合同单章文件'} file={record.moa_contract_file_single} isContract={true} contract={record._id} />
                            <DownloadLink title={'合同双章文件'} file={record.moa_contract_file_double} isContract={true} contract={record._id} />
                        </>
                        :
                        <DownloadLink title={'合同扫描文件'} file={record.moa_contract_file} isContract={true} contract={record._id} />
                }
            </div>
        )
    }

    getFileModal = (files) => {
        Modal.info({
            icon: null,
            title: "备注附件",
            okText: "关闭",
            content: (
                <div>
                    {
                        files.map((value, index) => (
                            <a key={value.url || index} onClick={() => this.handleDownloadFile(value)}>
                                <div>
                                    <LinkOutlined style={{ marginRight: 4 }} />
                                    {value.name || '-'}
                                </div>
                            </a>
                        ))
                    }
                </div>
            )
        });
    }

    handleDownloadFile = async (file) => {
        tools.downloadFile(this.props.history, file, `${memory.user.dd_info.name} ${moment().format("YYYY/MM/DD")}`);
    }

    getProjectManagerObj = async (params = {}) => {
        const response = await reqUserList(params);
        if (response?.data) {
            this.projectManagerObj = {};
            response.data.data.forEach(value => {
                this.projectManagerObj[value._id] = {
                    name: value.dd_info.name,
                    id: value._id,
                }
            });
        }
    }

    handleOperationLogListChange = (pagination) => {
        this.getAction({
            page: pagination.current,
            node: pagination.pageSize,
            object_type: "project",
            object_id: this.id,
            action_name: PROJECT_ACTION_NAME.join(","),
        });
    }

    getOperationLogList = async (params = {}) => {
        const response = await reqOperationLogList(params);
        if (response?.data) {
            let data = [];
            for (let i = 0; i < response.data.msg.data.length; i++) {
                const value = response.data.msg.data[i];
                let item = {};
                item.key = value._id;
                item.operation_at = value.operation_at;
                item.operation_type = value.operation_type;
                item.operator = value?.operator?.dd_info?.name || "-";
                if (value.operation_type === "create") {
                    item.operation_content = [`1、${item.operator}创建了该项目`];
                } else if (value.operation_type === "delete") {
                    item.operation_content = [`1、${item.operator}删除了该项目`];
                } else {
                    item.operation_content = [];
                    let count = 0;
                    for (let j = 0; j < value.operation_content.length; j++) {
                        const operationItem = value.operation_content[j];
                        let key = operationItem.key.split(".");
                        if (this.PROJECT_CONTENTS[key[0]]) {
                            count++;
                            if (operationItem.new_value && operationItem.new_value[0] === '"' && operationItem.new_value[operationItem.new_value.length - 1] === '"') {
                                operationItem.new_value = operationItem.new_value.slice(1, operationItem.new_value.length);
                                operationItem.new_value = operationItem.new_value.slice(0, operationItem.new_value.length - 1);
                            }
                            if (operationItem.old_value && operationItem.old_value[0] === '"' && operationItem.old_value[operationItem.old_value.length - 1] === '"') {
                                operationItem.old_value = operationItem.old_value.slice(1, operationItem.old_value.length);
                                operationItem.old_value = operationItem.old_value.slice(0, operationItem.old_value.length - 1);
                            }
                            if (operationItem.note === "ADDED") {
                                item.operation_content.push(
                                    <span>
                                        {count}、
                                        {item.operator}
                                        添加了
                                        {this.PROJECT_CONTENTS[key[0]].text}
                                        “{await this.PROJECT_CONTENTS[key[0]].format(operationItem.new_value)}”
                                    </span>
                                );
                            } else if (operationItem.note === "DELETED") {
                                item.operation_content.push(
                                    <span>
                                        {count}、
                                        {item.operator}
                                        删除了
                                        {this.PROJECT_CONTENTS[key[0]].text}
                                        “{await this.PROJECT_CONTENTS[key[0]].format(operationItem.old_value)}”
                                    </span>
                                );
                            } else {
                                item.operation_content.push(
                                    <span>
                                        {count}、
                                        {item.operator}
                                        将{this.PROJECT_CONTENTS[key[0]].text}
                                        从“{await this.PROJECT_CONTENTS[key[0]].format(operationItem.old_value)}”
                                        改为
                                        “{await this.PROJECT_CONTENTS[key[0]].format(operationItem.new_value)}”
                                    </span>
                                );
                            }
                        }
                    }
                }
                data.push(item);
            }
            const pagination = { ...this.state.operatonLogPagination };
            pagination.total = response.data.msg.page.total;
            pagination.pageSize = response.data.msg.page.node;
            pagination.current = response.data.msg.page.current;
            this.setState({
                operatonLogList: data,
                operatonLogPagination: pagination,
            });
        }
    }

    getAction = async (params = {}) => {
        const response = await reqNewDailyActionList(params);
        if (response?.data) {
            let arr = [];
            response.data.data.forEach((value, index) => {
                let item = {
                    key: index + value.time,
                    operator: value?.operator?.name,
                    operation_at: value?.time,
                    operation_type: value?.action_name,
                }
                item.operation_content = value?.contents?.map((value, index) => (
                    <div key={index} style={{ display: "flex", alignItems: "start", whiteSpace: "pre-line" }}>
                        {
                            value.key + "："
                        }
                        {
                            value.type === "file" ?
                                <div>
                                    {
                                        value.value?.length ?
                                            value.value.map(file => (
                                                <div key={file.url}>
                                                    <span onClick={() => this.handleDownloadFile(file)} style={{ cursor: "pointer", textDecoration: "underline" }}>
                                                        <LinkOutlined style={{ marginRight: 4 }} />
                                                        {file.name || '-'}
                                                    </span>
                                                </div>
                                            ))
                                            :
                                            "无"
                                    }
                                </div>
                                :
                                <div>
                                    {
                                        (value.value || "待补充")
                                    }
                                </div>
                        }
                    </div>
                ));
                arr.push(item);
            });
            const pagination = { ...this.state.operatonLogPagination };
            pagination.total = response.data.page.total;
            pagination.pageSize = response.data.page.node;
            pagination.current = response.data.page.current;
            this.setState({
                actionList: arr,
                operatonLogPagination: pagination
            })
        }
    }

    handleMessageCancel = () => {
        const { resetFields } = this.messageFormRef.current.formRef.current;
        this.setState({
            messageVisible: false
        });
        resetFields();
    }

    handleMessageCreate = () => {
        const { validateFields, resetFields } = this.messageFormRef.current.formRef.current;
        validateFields(["content", "attachments"])
            .then(async (values) => {
                let params = {
                    id: this.id,
                    content: values.content,
                };
                if (values.attachments?.length) {
                    params.attachments = values.attachments.map(value => ({ name: value.name, url: value.url }));
                }
                const response = await reqBusinessRemarksEdit(params);
                if (response && response.data) {
                    message.success('添加成功');
                    resetFields();
                    this.setState({ messageVisible: false });
                    // 获取详情
                    this.getBusinessDetail({
                        id: this.id
                    });
                }
            });
    }

    getRemainingTime = (date, is_blocked) => {
        let expiration_time = moment(date).add(EXPIRATION_DAYS, "days").valueOf();
        let nt = Date.parse(new Date()) / 1000;
        let et = expiration_time / 1000;
        let sub = et - nt;
        if (sub <= 0 || is_blocked) return <Tag color="#808080">已过期锁定</Tag>;
        else if (sub <= 60 * 60 * 24 * 3) return <Tag color="#f00">{tools.timerString1(expiration_time / 1000)}</Tag>;
        else return <Tag color="#87d068">{tools.timerString1(expiration_time / 1000)}</Tag>
    }

    handleInvoiceApply = async (contract) => {
        const response = await reqLastInvoice({ contractid: contract._id });
        if (response && response.data) {
            if (response.data.err_msg) {
                if (response.data.err_msg.contract.batch == contract.collection_times) {
                    message.error('财务已创建完该合同发票,无法再发起申请');
                } else {
                    if (this.props.id) {
                        window.open(`/crm/client/invoice-add/${contract._id}`);
                    } else {
                        this.props.history.push(`/crm/client/invoice-add/${contract._id}`);
                    }
                }
            } else {
                if (this.props.id) {
                    window.open(`/crm/client/invoice-add/${contract._id}`);
                } else {
                    this.props.history.push(`/crm/client/invoice-add/${contract._id}`);
                }
            }
        }
    }

    getBusinessDetail = async (params = {},getList=true) => {
        const response = await reqBusinessDetail(params);
        if (response && response.data) {
            let newData = [];
            for (let i = 0; i < response.data.schedule.length; i++) {
                const item = response.data.schedule[i];
                if (item.type === 1) {
                    newData.push({
                        key: item._id || i,
                        content: `创建了项目 ${response.data.project_name}`,
                        user: response.data.creator.name,
                        time: tools.unixToTime((new Date(item.create_at)).getTime() / 1000),
                        remark: item.remark || '无',
                        reason: response.data.lose_order_reason || [],
                        type: item.type,
                        data: item,
                        files: item.files || [],
                        estimate_gross_profit: item.estimate_gross_profit || '-',
                        update_by: item.update_by,
                    });
                } else {
                    newData.push({
                        key: item._id || i,
                        content: `项目进度 变为 ${BUSINESSSCHEDULE_OBJ[item.type]}`,
                        user: response.data.creator.name,
                        time: tools.unixToTime((new Date(item.create_at)).getTime() / 1000),
                        remark: item.remark || '无',
                        reason: response.data.lose_order_reason || [],
                        type: item.type,
                        data: item,
                        files: item.files || [],
                        estimate_gross_profit: item.estimate_gross_profit || '-',
                        update_by: item.update_by,
                    });
                }
            }
            if (response.data.bstate === 1 || response.data.bstate === 3) {
                let scheduleContent = '-';
                let scheduleRemark = '无';
                if (response.data.bstate === 1) {
                    scheduleContent = '赢单申请审批中...';
                } else {
                    scheduleContent = '赢单申请被驳回';
                    scheduleRemark = response.data.examined_content.content || '无';
                }
                newData.push({
                    key: response.data._id,
                    content: scheduleContent,
                    user: response.data.creator.name,
                    time: response.data.examined_content ? tools.unixToTime((new Date(response.data.examined_content.fail_at)).getTime() / 1000) : '-',
                    remark: scheduleRemark,
                    reason: [],
                    type: -1,
                    files: [],
                    bstate: response.data.bstate,
                    estimate_gross_profit: response.data.estimate_gross_profit || '-'
                });
            }
            let cooperator = [];
            if (response.data.team_worker && response.data.team_worker.length) {
                for (let i = 0; i < response.data.team_worker.length; i++) {
                    const item = response.data.team_worker[i];
                    item.key = item.id;
                    cooperator.push(item);
                }
            }

            //真实赢单时间
            response.data.real_signing_date = response.data?.schedule?.length && response.data.schedule.find(o => o.type == 6)?.create_at ? response.data.schedule.find(o => o.type == 6)?.create_at : null;
            this.setState({
                detail: response.data,
                loading: false,
                schedule: newData,
                cooperator: cooperator,
            });
            // 获取合同列表
            if(getList){
                this.getContractList({
                    bid: this.id
                });
            }
            
        }
    };

    getContractList = async (params = {}) => {
        let response;
        if (this.state.detail.creator.id === memory.user._id) {
            response = await reqContractSelf(params);
        } else {
            response = await reqContractList(params);
        }
        if (response && response.data) {
            let disbursement_no_paid = 0, disbursement_paid = 0;
            response.data.data.filter(o => PROCUREMENT_CONTRACT.indexOf(o.contract_type) > -1).forEach(value => {
                disbursement_paid += value.paid || 0;
                disbursement_no_paid += value.no_paid ?? (value.contract_amount || 0) - (value.paid || 0);
            });
            const { detail } = this.state;
            detail.disbursement_no_paid = disbursement_no_paid;
            detail.disbursement_paid = disbursement_paid;
            this.setState({
                contract: response.data.data,
                detail,
            });
        }
    };

    costApply = async (params = {}) => {
        this.setState({
            submitLoading: true
        })
        const response = await reqBusinessApply(params);
        if (response && response.data) {
            message.success('申请成功，请耐心等待审核');
            this.getBusinessDetail({
                id: this.id
            });
        }
        this.setState({
            submitLoading: false
        })
    };

    delWinApply = async (params = {}) => {
        const response = await reqBusinessDelWinApply({
            bid: this.id
        });
        if (response && response.data) {
            message.success('赢单申请撤回成功');
            window.location.reload();
        }
    }

    expandComponent = (record) => {
        let tags = [];
        if (record.reason.length) {
            for (let i = 0; i < record.reason.length; i++) {
                const item = record.reason[i];
                if (record.type === 7) {
                    const key = item.toString().slice(0, 1);
                    const loseFirst = BUSINESSQUESTION_OBJ[key];
                    const loseSecond = BUSINESSQUESTION_OBJ[item];
                    const lose = `${loseFirst}-${loseSecond}`;
                    tags.push(<Tag key={item} color="red">{lose || '-'}</Tag>);
                }
            }
        }
        return (
            <div>
                <p style={{ margin: 0 }}>
                    <span className="label">说明：</span>
                    <span>{record.remark || '-'}</span>
                </p>
                {record.type === 7 && record.reason.length ? (
                    <p style={{ marginBottom: 0, marginTop: 10 }}>
                        <span className="label">输单原因：</span>
                        {tags}
                    </p>
                ) : null}
            </div>
        );
    };

    getWeaponList = async (params, cb) => {
        const response = await reqWeaponList(params);
        if (response && response.data) {
            this.weaponListObj = {};
            response.data.data.map((value, index) => {
                this.weaponListObj[value._id] = value.productname;
            })
            if (cb) {
                cb({
                    id: this.id
                })
            }
        }
    }

    getPreSellerList = async (params = {}, callback) => {
        const response = await reqPreSellerList(params);
        if (response?.data) {
            response.data.data.forEach((value, index) => {
                this.preSellerList[value._id] = value.dd_info.name;
                this.setState({});
                if (callback) callback({ id: this.id });
            })
        }
    }

    returnCancel = () => {
        this.setState({
            returnVisible: false,
        })
    }

    returnCreate = () => {
        this.getBusinessDetail({ id: this.id });
        this.setState({
            returnVisible: false,
        })
    }

    returnEdit = () => {
        this.setState({
            returnVisible: false,
        })
    }

    handleReturnEdit = () => {
        Modal.confirm({
            content: "仅在发票存在作废情况时修改批次,确认是否修改批次?",
            onOk: () => {
                this.setState({
                    returnVisible: true,
                });
            }
        });
    }

    getReturnList = async (params = {}) => {
        const response = await reqReturnList(params);
        if (response && response.data) {
            let arr = [];
            for (let i = 0; i < response.data.data.length; i++) {
                let item = response.data.data[i];
                arr.push({
                    amount: item.amount != null ? tools.formatNumber(item.amount) + '元' : '-',
                    proportion_amount: item.amount || 0,
                    return_at: item.payment_at ? moment(item.payment_at).format('YYYY/MM/DD') : '-',
                    creator: item.creator && item.creator.name ? item.creator.name : '-',
                    create_at: item.create_at ? moment(item.create_at).format('YYYY/MM/DD HH:mm:ss') : '-',
                    remark: item.remarks || '-',
                });
            }
            this.setState({
                returnedList: arr,
            })
        }
    }

    getMarketList = async (params = {}) => {
        const response = await reqMarketingBusinessList(params);
        if (response && response.data) {
            let arr = [];
            let calcAmount=0
            for (let i = 0; i < response.data.data.length; i++) {
                let item = response.data.data[i];
                calcAmount=Math.ceil(((item.data.amount/(item.data.proportion || 1) ?? 0)))
                let obj = {
                    key: item._id,
                    id: item._id,
                    requestAmount:item.data.amount == null ? "0元" : (item.data.amount),
                    proportion:item.data?.proportion ? ((item.data?.proportion*100)+'%'):"-",
                    amount:calcAmount || "-",
                    
                    market_form: item.data.market_form ? MARKETCOST_FORM_OBJ[item.data.market_form] : '-',
                    market_nature: item.data.market_nature ? MARKETCOST_TYPE_OBJ[item.data.market_nature] : '-',
                    //requestAmount:item.data.amount == null ? "0元" : item.data.amount + '元',
                    time: item.data.time ? moment(item.data.time).format('YYYY/MM/DD') : '-',
                    stage: item.data.stage,
                    is_history: item.data.is_history,
                    is_examined: item.is_examined,
                    create_at: item.create_at ? moment(item.create_at).format('YYYY/MM/DD HH:mm:SS') : '-',
                    creator: item.creator.name,
                }
                arr.push(obj);
                if (item?.data?.refund_history?.length) {
                    let refund_amount = 0;
                    item.data.refund_history.forEach((value, index) => {
                        refund_amount += (value.refund_amount || 0);
                        let obj = {
                            key: "refund_history" + (index + 1),
                            id: item._id,
                            proportion:"-",
                            requestAmount:value.refund_amount == null ? "0元" : `-${value.refund_amount}元`,
                            market_form: item.data.market_form ? MARKETCOST_FORM_OBJ[item.data.market_form] : '-',
                            market_nature: item.data.market_nature ? MARKETCOST_TYPE_OBJ[item.data.market_nature] : '-',
                            amount: value.refund_amount == null ? "0元" : `-${value.refund_amount}元`,
                            time: '-',
                            stage: item.data.stage,
                            is_history: item.data.is_history,
                            is_examined: item.is_examined,
                            create_at: value.time ? moment(value.time).format('YYYY/MM/DD HH:mm:SS') : '-',
                            creator: value.user.name,
                        }
                        arr.push(obj);
                    });
                    
                    obj.amount =calcAmount + (refund_amount ?? 0);
                    obj.amount+='元'
                    if(item.data.proportion){
                        obj.requestAmount+= (refund_amount ?? 0)
                    }
                    if (item?.data?.refund_history?.length) {
                        obj.requestAmount=item.data.refund_history[0].original_amount+'元'
                    }

                }
                if(!isNaN(obj.requestAmount))
                    obj.requestAmount+='元'
                
            }
            this.setState({
                marketCostList: arr,
            })
        }
    }

    handlePopconfirmVisible = async (visible) => {
        let { detail } = this.state
        if (visible) {
            const response = await reqPreSellerCheck({
                cid: detail.customer,
            })
            if (response?.data) {
                if (response.data.data) {
                    this.setState({
                        popconfirmVisible: visible,
                    })
                } else {
                    message.error('有项目经理申请正在审批中，请先等待申请完结!', 5);
                }
            }
        }
    }

    handleClose = async (value) => {

        if( !memory.user.role_type.includes(CRMADMIN)){
            message.error('没有结项权限')
            return
        }
        Modal.confirm({
            title:"操作确认",
            content:"确认结项",
            onOk:async ()=>{
                const response = await reqBusinessEdit({ id: this.id, closed: !this.state.detail.closed ? 1 : 0 });
                if (response?.data) {
                    message.success("修改成功");
                    this.getBusinessDetail({ id: this.id },false);
                }
            }
        })
        
    }

    handleWinProject = async () => {
        let schedule = {
            type: 6,
            remark: "项目合同管理员的项目直接赢单",
        }
        const response = await reqBusinessProjectEdit({
            id: this.id,
            schedule
        });
        if (response?.data) {
            message.success("赢单成功");
            this.getBusinessDetail({
                id: this.id,
            });
        }
    }

    handleFinishedProject = async (values) => {
        const response = await reqBusinessFinished({
            id:this.id, 
            finished: true,
            finish_status:values.finishType,
            finish_remarks: values.finish_remarks
        });
        if (response?.data) {
            message.success("修改成功");
            this.getBusinessDetail({ id: this.id });
            this.setState({
                finishOpen:false
            })
        }
    }

    handleMessageExpanded = (record) => {
        return (
            record?.attachments?.length ?
                <div style={{ width: "80vw" }}>
                    {
                        record?.attachments?.length ?
                            <DownloadLink title={'附件'} file={record.attachments} style={{ justifyContent: "unset" }} />
                            :
                            null
                    }
                </div>
                :
                null
        )
    }

    getBusinessScheduleButton = () => {
        const { detail } = this.state;
        if (this.isProjectContractAdmin && detail.creator.id === memory.user._id) {//项目合同管理员能直接赢单
            if (detail.stage === 6) {
                return <Button disabled>已赢单</Button>;
            }
            return (
                <Popconfirm
                    title="确定将该项目的进度变为赢单？"
                    okText="确定"
                    cancelText="取消"
                    onConfirm={this.handleWinProject}
                >
                    <Button type="primary">赢单</Button>
                </Popconfirm>
            )
        }

        if (this.isManagerAdmin) {//公司管理能输单
            if (detail.bstate === 1 || detail.stage === 6 || detail.stage === 7) {
                return (
                    <Tooltip
                        placement="top"
                        title={detail.stage === 6 ? '项目已赢单' : (detail.stage === 7 ? '项目已输单' : '赢单审批中')}
                    >
                        {
                            detail.stage === 7 ?
                                <Button disabled >输单</Button>
                                :
                                <Button disabled >输单请联系管理员</Button>
                        }
                    </Tooltip>
                )
            }
            return <LinkButton to={`/crm/client/schedule-add/lose/${this.id}`} target={this.props.id ? "_blank" : undefined} txt="输单" />
        }

        if (detail.creator.id === memory.user._id || detail.project_managers?.findIndex(o => o._id === memory.user._id) > -1) {//销售、项目经理能输单
            if (detail.bstate === 1 || detail.stage === 6 || detail.stage === 7) {
                return (
                    <Tooltip
                        placement="top"
                        title={detail.stage === 6 ? '项目已赢单' : (detail.stage === 7 ? '项目已输单' : '赢单审批中')}
                    >
                        <Button disabled >输单</Button>
                    </Tooltip>
                )
            }
            return <LinkButton to={`/crm/client/schedule-add/lose/${this.id}`} target={this.props.id ? "_blank" : undefined} txt="输单" />
        }
        return null;
    }
    getCheckProgress=async ()=>{
        let data=await reqCheckProgress({ project: this.id })
        data=data.data
        let contractData=(data?.msg?.contract || []).map(item=>{
            return {
                type:"contract",
                stage:item.detail.data.stage,
                examined_content:item.detail.examined_content || null,
                ...item
            }
        })
        let quotationData=(data?.msg?.quotation || []).map(item=>{
            return {
                type:"quotation",
                examined_content:item.detail.feedback || null,
                ...item
            }
        })
        let winData=(data?.msg?.win || []).map(item=>{
            return {
                type:"win",
                examined_content:item.detail.examined_content || null,
                ...item
            }
        })
        this.setState({
            contractData,
            quotationData,
            winData
        })
    }
    componentWillMount() {
        let adminIntersect = tools.getIntersect(memory.user.role_type, [ADMIN, CRMADMIN, HRBP]);
        if (adminIntersect && adminIntersect.length) {
            this.admin_action = true;
        }
    }

    componentDidMount() {
        if (this.isVENDORADMIN) {
            this.isSimple = true;
        }
        this.getWeaponList({
            node: 9999
        }, () => {
            if (!this.isSimple) {
                this.getPreSellerList({ page: 1, node: 99 });
            }
        })
        this.getBusinessDetail({ id: this.id });
        if (!this.isSimple) {
            this.getReturnList({
                business: this.id,
                node: 999
            });
            this.getMarketList({
                businessopportunity: this.id,
                node: 999
            });
            this.getAction({
                page: 1,
                object_type: "project",
                object_id: this.id,
                action_name: PROJECT_ACTION_NAME.join(","),
            });
        }
        //获取审批流程
        this.getCheckProgress()
    }
    render() {
        const { detail } = this.state;
        let tmp = [];
        if (detail.business_type && detail.business_type.length) {
            if (!detail.business_type.find(o => tools.isNumber(o) === false)) {
                for (let j = 0; j < detail.business_type.length; j++) {
                    tmp.push(BUSINESSTYPE_OBJ[detail.business_type[j]]);
                }
            } else {
                for (let j = 0; j < detail.business_type.length; j++) {
                    tmp.push(this.weaponListObj ? this.weaponListObj[detail.business_type[j]] : undefined);
                }
            }
        }
        let businessLevel = '待核算';
        if (detail.gross_profit) { //有毛利根据毛利计算
            if (detail.gross_profit >= 150000) {
                detail.type = 1;
            } else if (detail.gross_profit >= 100000) {
                detail.type = 2;
            } else if (detail.gross_profit >= 50000) {
                detail.type = 3;
            } else {
                detail.type = 4;
            }
        }
        if (detail.type) {
            businessLevel = BUSINESSLEVEL[detail.type - 1].text;
        }
        let grossProfit = ''; // 项目提成
        let grossProfit2 = '-'; // 推荐人项目提成
        if (detail.gross_profit != null) {
            if (detail.gross_profit < 0) {
                grossProfit = '0元';
            } else {
                const profit = detail.gross_profit * SELLERCONFIG.royalty_ratio;
                if (detail.recommender && detail.recommender.type) {
                    if (detail.recommender.type === 1) {
                        grossProfit = profit * 0.8;
                        grossProfit2 = profit * 0.2;
                    } else {
                        grossProfit = profit * 0.2;
                        grossProfit2 = profit * 0.8;
                    }
                    grossProfit = `${Number(grossProfit).toFixed(2)}元`;
                    grossProfit2 = `${Number(grossProfit2).toFixed(2)}元`;
                } else {
                    grossProfit = profit;
                    grossProfit = `${Number(grossProfit).toFixed(2)}元`;
                }
            }
        } else {
            grossProfit = '待核算';
        }

        let customerName = detail?.customer?.company_name ?? detail?.customer_name;
        let marketingCostApplied = '';
        if (detail?.marketing_cost_apply?.finished || detail?.marketing_cost_apply?.waiting) {
            marketingCostApplied = `(审核中：${detail?.marketing_cost_apply?.waiting || 0} 已申请：${detail?.marketing_cost_apply?.finished || 0})`;
        }

        return this.state.loading ? (
            <div className="loading-container">
                <Spin size="large" />
            </div>
        ) : (
            <>
                {
                    this.props.id ?
                        null
                        :
                        <Button
                            type="link"
                            icon={<LeftOutlined />}
                            onClick={() => {
                                this.props.history.goBack();
                            }}
                        >返回</Button>
                }

                <Tabs defaultActiveKey="basic" type="card" tabBarStyle={{ marginBottom: 5 }}>
                    {
                        this.isSimple ?
                            <Tabs.TabPane key="basic" tab="基本信息">
                                <Card
                                    title="项目信息"
                                    style={{
                                        marginBottom: 24,
                                    }}
                                    bordered={false}
                                >
                                    <Descriptions
                                        style={{
                                            marginBottom: 24,
                                        }}
                                        title="基本信息"
                                    >
                                        <Descriptions.Item label="项目编号">
                                            {detail.business_number || '待审批'}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="项目名称">
                                            {detail.project_name || '-'}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="所属客户">
                                            {customerName || '-'}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="跟进销售">
                                            {detail.creator.name || '-'}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="关联项目经理">
                                            {
                                                detail?.project_managers?.length ? (detail.project_managers.map((value, index) => (value?.dd_info?.name || "-")).join(",")) : "-"
                                            }
                                        </Descriptions.Item>
                                        <Descriptions.Item label="合同编号">
                                            {detail.project_number || '-'}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="合同金额">
                                            {
                                                (detail.contract_amount ? tools.formatNumber(detail.contract_amount) : 0) + '元'
                                            }
                                        </Descriptions.Item>
                                        <Descriptions.Item label="赢单时间">
                                            {
                                                detail?.real_signing_date ? moment(detail?.real_signing_date).format("YYYY-MM-DD") : "-"
                                            }
                                        </Descriptions.Item>
                                        <Descriptions.Item label="已回款金额">
                                            {detail.paid != null ? `${tools.formatNumber(detail.paid)}元` : "0元"}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="待回款金额">
                                            {detail.no_paid != null ? `${tools.formatNumber(detail.no_paid)}元` : (detail.stage === 6 && detail.contract_amount ? `${tools.formatNumber(detail.contract_amount)}元` : '-')}
                                        </Descriptions.Item>
                                        
                                        <Descriptions.Item label="回款状态">
                                            {
                                                detail.finished != null ?
                                                    (
                                                        detail.finished ?
                                                            <Tag color="#87d068" >回款完毕{
                                                                detail.finish_status>=0
                                                                ?`,${FINISH_STATUS[detail.finish_status]}`
                                                                :''
                                                            }</Tag>
                                                            :
                                                            (
                                                                this.isProjectFinished ?
                                                                    <>
                                                                        <Tag color="#ff5500" >待回款</Tag>
                                                                        
                                                                        <a onClick={()=>{
                                                                            this.setState({
                                                                                finishOpen:true
                                                                            })
                                                                        }}>回款完毕</a>
                                                                    </>
                                                                    :
                                                                    <Tag color="#ff5500" >待回款</Tag>
                                                            )
                                                    )
                                                    :
                                                    "-"
                                            }
                                        </Descriptions.Item>
                                    </Descriptions>
                                </Card>
                            </Tabs.TabPane>
                            :
                            <Tabs.TabPane key="basic" tab="基本信息">
                                <Card
                                    title="项目信息"
                                    style={{
                                        marginBottom: 24,
                                    }}
                                    bordered={false}
                                    extra={
                                        <div style={{
                                            width: 'auto',
                                            display: 'flex',
                                            gap:'10px'
                                        }}>
                                            
                                            <Button type="primary" disabled={detail?.closed} onClick={this.handleClose}>{!detail?.closed ? '未结项，确认结项' : '已结项'}</Button>
                                        
                                            {
                                            
                                            (detail && (detail.creator.id === memory.user._id || detail.project_managers.find(item => item._id === memory.user._id))) || this.isManagerAdmin || this.isCRMAdmin || this.isPreSellerAdmin ? (
                                                

                                                    
                                                    <LinkButton to={`/crm/client/business-edit/${this.id}`} txt="编辑" target={this.props.id ? "_blank" : undefined} />
                                                

                                            ) : null}
                                            </div>
                                        }
                                >
                                    <Descriptions
                                        style={{
                                            marginBottom: 24,
                                        }}
                                        title="基本信息"
                                    >
                                        <Descriptions.Item label="项目名称">
                                            {detail.project_name || '-'}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="所属客户">
                                            {customerName || '-'}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="项目分类">
                                            {tmp.length ? tmp.join('，') : '-'}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="项目性质">
                                            {BUSINESSNATURE[detail.project_type - 1].text || '-'}
                                        </Descriptions.Item>
                                        <Descriptions.Item label={<span style={{ fontWeight: 'bolder' }}>是否是标准项目</span>}>
                                            <span style={{ fontWeight: 'bolder' }}>{detail.specifications != null ? (detail.specifications == 1 ? '是' : '否') : '-'}</span>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="合同金额">
                                            {
                                                (detail.contract_amount ? tools.formatNumber(detail.contract_amount) : 0) + '元'
                                            }
                                        </Descriptions.Item>
                                        <Descriptions.Item label="项目等级">
                                            {businessLevel}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="项目编号">
                                            {detail.business_number || detail.project_number || '待审批'}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="跟进销售">
                                            {detail.creator.name || '-'}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="项目归属">
                                            {detail.worker_type ? BUSINESS_BELONG[detail.worker_type] : '-'}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="预计签单日期">
                                            {tools.unixToTime((new Date(detail.expected_signing_date)).getTime() / 1000, true)}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="项目描述">
                                            {detail.project_description || '-'}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="关联项目经理">
                                            {
                                                detail?.project_managers?.length ? (detail.project_managers.map((value, index) => (value?.dd_info?.name || "-")).join(",")) : "-"
                                            }
                                        </Descriptions.Item>
                                        {/*
                                            this.isManagerAdmin ?
                                                <Descriptions.Item label={"商机状态"}>
                                                    <Switch checked={!detail?.closed} checkedChildren="打开" unCheckedChildren="关闭" onChange={this.handleClose} />
                                                </Descriptions.Item>
                                                :
                                                null
                                        */}
                                    </Descriptions>

                                    <Descriptions
                                        style={{
                                            marginBottom: 24,
                                        }}
                                        title="成本信息"
                                    >
                                        <Descriptions.Item label="合同金额">
                                            {
                                                (detail.contract_amount ? tools.formatNumber(detail.contract_amount) : 0) + '元'
                                            }
                                        </Descriptions.Item>
                                        <Descriptions.Item label="上游成本">
                                            {
                                                !detail.gross_profit && detail.upstream_cost == null ? '待审批' : (detail.upstream_cost === -1 ? '审批中' : `${detail.upstream_cost}元`)
                                            }
                                        </Descriptions.Item>
                                        <Descriptions.Item label="项目税费">
                                            {
                                                !detail.gross_profit || detail.project_tax == null ? '待审批' : (detail.project_tax === -1 ? '审批中' : `${detail.project_tax.toFixed(2)}元`)
                                            }
                                        </Descriptions.Item>
                                        <Descriptions.Item label="上游税费">
                                            {
                                                !detail.gross_profit || detail.upstream_tax == null ? '待审批' : (detail.upstream_tax === -1 ? '审批中' : `${detail.upstream_tax.toFixed(2)}元`)
                                            }
                                        </Descriptions.Item>
                                        <Descriptions.Item label="服务成本">
                                            {
                                                !detail.gross_profit || detail.service_cost == null ? '待审批' : (detail.service_cost === -1 ? '审批中' : `${detail.service_cost}元`)
                                            }
                                        </Descriptions.Item>
                                        <Descriptions.Item label="项目经理成本">
                                            {
                                                !detail.gross_profit || detail.pre_cost == null ? '待审批' : (detail.pre_cost === -1 ? '审批中' : `${detail.pre_cost}元`)
                                            }
                                        </Descriptions.Item>
                                        <Descriptions.Item label="其他成本">
                                            {
                                                !detail.gross_profit || detail.average_cost == null ? '待审批' : (detail.average_cost === -1 ? '审批中' : `${detail.average_cost}元`)
                                            }
                                        </Descriptions.Item>
                                        <Descriptions.Item label="产品金额">
                                            {
                                                !detail.gross_profit || detail.product_contract_amount == null ? '待审批' : (detail.product_contract_amount === -1 ? '审批中' : `${tools.formatNumber(detail.product_contract_amount)}元`)
                                            }
                                        </Descriptions.Item>
                                        <Descriptions.Item label="服务金额">
                                            {
                                                !detail.gross_profit || detail.service_contract_amount == null ? '待审批' : (detail.service_contract_amount === -1 ? '审批中' : `${tools.formatNumber(detail.service_contract_amount)}元`)
                                            }
                                        </Descriptions.Item>
                                        <Descriptions.Item label="额外费用">
                                            {
                                                detail.gross_profit == null || detail.gross_profit == -1 ?
                                                    (detail.marketing_cost == null ? `待审批${marketingCostApplied}` : (detail.marketing_cost === -1 ? `审批中${marketingCostApplied}` : `${detail.marketing_cost}元${marketingCostApplied}`))
                                                    :
                                                    (detail.marketing_cost == null || detail.marketing_cost == -1 ? `0元${marketingCostApplied}` : `${detail.marketing_cost}元${marketingCostApplied}`)
                                            }
                                        </Descriptions.Item>
                                        <Descriptions.Item label="公司均摊成本">
                                            {
                                                !detail.gross_profit || detail.return_to_factory == null ? '待审批' : (detail.return_to_factory === -1 ? '审批中' : `${detail.return_to_factory}元`)
                                            }
                                        </Descriptions.Item>
                                        <Descriptions.Item label="项目毛利">
                                            {
                                                detail.gross_profit == null ? '待核算' : (detail.gross_profit === -1 ? '审批中' : `${detail.gross_profit}元`)
                                            }
                                        </Descriptions.Item>
                                        <Descriptions.Item label="毛利率">
                                            {
                                                detail.gross_profit == null ? '待核算' : (!detail.contract_amount ? '0%' : (detail.gross_profit === -1 ? '审批中' : `${(detail.gross_profit / detail.contract_amount * 100).toFixed(2)}%`))
                                            }
                                        </Descriptions.Item>
                                        {
                                            memory.seller || this.admin_action ? (
                                                <>
                                                    {
                                                        detail.real_signing_date && moment(detail.real_signing_date).year() < 2022 ?
                                                            <Descriptions.Item label="项目提成">
                                                                {
                                                                    grossProfit
                                                                }
                                                            </Descriptions.Item>
                                                            :
                                                            null
                                                    }
                                                    {/* <Descriptions.Item label="报销额度">
                                        {
                                            detail.gross_profit == null? '待核算' : (detail.gross_profit < 0 ? '0元' : `${Number(detail.gross_profit * detail.reimbursement_rate).toFixed(2)}元`)
                                        }
                                    </Descriptions.Item> */}
                                                </>
                                            ) : null
                                        }
                                    </Descriptions>

                                    <Descriptions
                                        style={{
                                            marginBottom: 24,
                                        }}
                                        title="回款与付款信息"
                                    >
                                        <Descriptions.Item label="合同金额">
                                            {
                                                (detail.contract_amount ? tools.formatNumber(detail.contract_amount) : 0) + '元'
                                            }
                                        </Descriptions.Item>
                                        <Descriptions.Item label="已回款金额">
                                            {detail.paid != null ? `${tools.formatNumber(detail.paid)}元` : "0元"}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="待回款金额">
                                            {detail.no_paid != null ? `${tools.formatNumber(detail.no_paid)}元` : (detail.stage === 6 && detail.contract_amount ? `${tools.formatNumber(detail.contract_amount)}元` : '-')}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="回款状态">
                                            {
                                                detail.finished != null ?
                                                    (
                                                        detail.finished ?
                                                            <Tag color="#87d068" >回款完毕{
                                                                detail.finish_status
                                                                ?`,${FINISH_STATUS[detail.finish_status]}`
                                                                :''
                                                            }</Tag>
                                                            :
                                                            (
                                                                this.isProjectFinished ?
                                                                    <>
                                                                        <Tag color="#ff5500" >待回款</Tag>
                                                                        <a onClick={()=>{
                                                                                this.setState({
                                                                                    finishOpen:true
                                                                                })
                                                                            }}>回款完毕</a>
                                                                    </>
                                                                    :
                                                                    <Tag color="#ff5500" >待回款</Tag>
                                                            )
                                                    )
                                                    :
                                                    "-"
                                            }
                                        </Descriptions.Item>
                                        <Descriptions.Item label="上游成本">
                                            {
                                                !detail.gross_profit || detail.upstream_cost == null ? '待审批' : (detail.upstream_cost === -1 ? '审批中' : `${detail.upstream_cost}元`)
                                            }
                                        </Descriptions.Item>
                                        <Descriptions.Item label="已付款金额">
                                            {detail.disbursement_paid != null ? `${tools.formatNumber(detail.disbursement_paid)}元` : "0元"}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="待付款金额">
                                            {detail.disbursement_no_paid != null ? `${tools.formatNumber(detail.disbursement_no_paid)}元` : "0元"}
                                        </Descriptions.Item>
                                    </Descriptions>

                                    <Descriptions
                                        style={{
                                            marginBottom: 24,
                                        }}
                                        title="推荐人信息"
                                    >
                                        <Descriptions.Item label="推荐人">
                                            {detail.recommender && detail.recommender.name ? detail.recommender.name : '-'}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="推荐级别">
                                            {detail.recommender && detail.recommender.type ? RECOMMEND_TYPE_OBJ[detail.recommender.type] : '-'}
                                        </Descriptions.Item>
                                    </Descriptions>
                                </Card>
                                <Card
                                    title="项目合作者"
                                    style={{
                                        marginBottom: 24,
                                    }}
                                    bordered={false}
                                >
                                    <Table
                                        dataSource={this.state.cooperator}
                                        columns={this.columns}
                                        pagination={false}
                                    />
                                </Card>
                                <Card
                                    title="项目备注"
                                    style={{
                                        marginBottom: 24,
                                    }}
                                    bordered={false}
                                    extra={
                                        this.isCRMAdmin || this.isPreSellerAdmin || memory.user._id === detail.creator?.id || detail.project_managers?.findIndex(o => o._id === memory.user._id) > -1 ?
                                            <Button type="primary" onClick={() => this.setState({ messageVisible: true })} >添加备注</Button>
                                            :
                                            null
                                    }
                                >
                                    <Table
                                        columns={messageColumns}
                                        dataSource={this.state.detail?.remarks || []}
                                        scroll={{ x: 'max-content' }} // 加上这条  横向滚动  支持此属性的浏览器内容就不会换行了
                                        size={memory.is_mobile ? 'small' : 'middle'}
                                        rowKey="_id"
                                        expandedRowRender={this.handleMessageExpanded}
                                        expandRowByClick={true}
                                    />
                                    <MessageCollectionCreateForm
                                        ref={this.messageFormRef}
                                        visible={this.state.messageVisible}
                                        onCancel={this.handleMessageCancel}
                                        onCreate={this.handleMessageCreate}
                                    />
                                </Card>
                            </Tabs.TabPane>
                    }
                    {
                        this.isSimple ?
                            null
                            :
                            <Tabs.TabPane key="progress" tab="项目进度">
                                <Card
                                    title="项目进度管理"
                                    style={{
                                        marginBottom: 24,
                                    }}
                                    bordered={false}
                                    extra={
                                        this.getBusinessScheduleButton()
                                    }
                                >
                                    <Table
                                        columns={this.scheduleColumns}
                                        expandedRowRender={this.expandComponent}
                                        defaultExpandAllRows
                                        //expandRowByClick={true}
                                        dataSource={this.state.schedule}
                                        pagination={false}
                                        scroll={{ x: 'max-content' }} // 加上这条  横向滚动  支持此属性的浏览器内容就不会换行了
                                        size={memory.is_mobile ? 'small' : 'middle'}
                                    />
                                </Card>
                            </Tabs.TabPane>
                    }
                    <Tabs.TabPane key="contract" tab="项目合同">
                        <Card
                            title="合同管理"
                            style={{
                                marginBottom: 24,
                            }}
                            bordered={false}
                        >
                            {
                                this.isSimple && !this.isVENDORADMIN ?
                                    <Table
                                        columns={this.simpleContractColumns}
                                        dataSource={this.state.contract}
                                        pagination={false}
                                        expandedRowRender={this.handleContractExpanded}
                                        expandRowByClick={true}
                                        scroll={{ x: 'max-content' }} // 加上这条  横向滚动  支持此属性的浏览器内容就不会换行了
                                        size={memory.is_mobile ? 'small' : 'middle'}
                                    />
                                    :
                                    <Tabs defaultActiveKey="seller">
                                        <Tabs.TabPane key="seller" tab="销售合同" >
                                            <Table
                                                columns={this.sellerContractColumns}
                                                dataSource={this.state.contract.filter(o => SELLER_CONTRACT.includes(o.contract_type))}
                                                pagination={false}
                                                expandedRowRender={this.getContractExpanded}
                                                expandRowByClick={true}
                                                scroll={{ x: 'max-content' }} // 加上这条  横向滚动  支持此属性的浏览器内容就不会换行了
                                                size={memory.is_mobile ? 'small' : 'middle'}
                                            />
                                        </Tabs.TabPane>
                                        <Tabs.TabPane key="procurement" tab="采购合同" >
                                            <Table
                                                columns={this.procurementContractColumns}
                                                dataSource={this.state.contract.filter(o => PROCUREMENT_CONTRACT.includes(o.contract_type))}
                                                pagination={false}
                                                expandedRowRender={this.getContractExpanded}
                                                expandRowByClick={true}
                                                scroll={{ x: 'max-content' }} // 加上这条  横向滚动  支持此属性的浏览器内容就不会换行了
                                                size={memory.is_mobile ? 'small' : 'middle'}
                                            />
                                        </Tabs.TabPane>
                                        <Tabs.TabPane key="other" tab="其他合同" >
                                            <Table
                                                columns={this.otherContractColumns}
                                                dataSource={this.state.contract.filter(o => !SELLER_CONTRACT.concat(PROCUREMENT_CONTRACT).includes(o.contract_type))}
                                                pagination={false}
                                                expandedRowRender={this.getContractExpanded}
                                                expandRowByClick={true}
                                                scroll={{ x: 'max-content' }} // 加上这条  横向滚动  支持此属性的浏览器内容就不会换行了
                                                size={memory.is_mobile ? 'small' : 'middle'}
                                            />
                                        </Tabs.TabPane>
                                    </Tabs>
                            }
                            <ReturnCreateForm
                                visible={this.state.returnVisible}
                                business={detail}
                                contract={this.state.returnFormContract}
                                onCancel={this.returnCancel}
                                onCreate={this.returnCreate}
                                type={this.state.returnFormType}
                            />
                        </Card>
                    </Tabs.TabPane>
                    {
                        this.isSimple ?
                            null
                            :
                            <Tabs.TabPane key="return" tab="项目回款">
                                <Card
                                    title="回款记录"
                                    style={{
                                        marginBottom: 24,
                                    }}
                                    bordered={false}
                                >
                                    <Table
                                        columns={this.returnedColumns}
                                        dataSource={this.state.returnedList}
                                        pagination={false}
                                        scroll={{ x: 'max-content' }} // 加上这条  横向滚动  支持此属性的浏览器内容就不会换行了
                                        size={memory.is_mobile ? 'small' : 'middle'}
                                    />
                                </Card>
                            </Tabs.TabPane>
                    }
                    {
                        !this.isCRMAdmin && !this.isFINANCE && detail?.creator?.id !== memory.user._id ?
                            null
                            :
                            <Tabs.TabPane key="marketCost" tab="额外费用">
                                <Card
                                    title="额外费用记录"
                                    style={{
                                        marginBottom: 24,
                                    }}
                                    extra={
                                        (memory.user.roles.some((item) => item.type === 45 || item.type === 78 ) || detail?.creator?.id === memory.user._id ) ?
                                            <Button
                                                type='primary'
                                                onClick={() => {
                                                    if (this.props.id) {
                                                        window.open(`/crm/client/marketCost-add/${detail._id}`);
                                                    } else {
                                                        this.props.history.push(`/crm/client/marketCost-add/${detail._id}`);
                                                    }

                                                }}
                                            >额外费用申请</Button>
                                            :
                                            null
                                    }
                                    bordered={false}
                                >
                                    <Table
                                        columns={this.marketCostColumns}
                                        dataSource={this.state.marketCostList}
                                        pagination={false}
                                        scroll={{ x: 'max-content' }} // 加上这条  横向滚动  支持此属性的浏览器内容就不会换行了
                                        size={memory.is_mobile ? 'small' : 'middle'}
                                    />
                                </Card>
                            </Tabs.TabPane>
                    }
                    {
                        this.isSimple ?
                            null
                            :
                            <Tabs.TabPane key="operation" tab="日志">
                                <Card
                                    title="操作记录"
                                    style={{
                                        marginBottom: 24,
                                    }}
                                    bordered={false}
                                >
                                    <Table
                                        columns={operationLogColumns}
                                        dataSource={this.state.actionList}
                                        scroll={{ x: 'max-content' }} // 加上这条  横向滚动  支持此属性的浏览器内容就不会换行了
                                        size={memory.is_mobile ? 'small' : 'middle'}
                                        expandedRowRender={(record) => (
                                            <div>
                                                {
                                                    record.operation_content.map((value, index) => (
                                                        <div key={index}>{value}</div>
                                                    ))
                                                }
                                            </div>
                                        )}
                                        expandRowByClick
                                        pagination={this.state.operatonLogPagination}
                                        onChange={this.handleOperationLogListChange}
                                    />
                                </Card>
                            </Tabs.TabPane>
                    }
                     <Tabs.TabPane key="check" tab="审批流程">
                        <Card
                            title="审批流程"
                            style={{
                                marginBottom: 24,
                            }}
                            bordered={false}
                        >
                            <Tabs defaultActiveKey="seller">
                                <Tabs.TabPane key="quotation" tab="报价单审批流程" >
                                    <Table
                                        columns={this.checkColumns}
                                        dataSource={this.state.quotationData}
                                        pagination={false}
                                        expandRowByClick={true}
                                        scroll={{ x: 'max-content' }} // 加上这条  横向滚动  支持此属性的浏览器内容就不会换行了
                                        size={memory.is_mobile ? 'small' : 'middle'}
                                    />
                                </Tabs.TabPane>
                                <Tabs.TabPane key="contract" tab="合同审批流程" >
                                    <Table
                                        columns={this.checkColumns}
                                        dataSource={this.state.contractData}
                                        pagination={false}
                                        expandRowByClick={true}
                                        scroll={{ x: 'max-content' }} // 加上这条  横向滚动  支持此属性的浏览器内容就不会换行了
                                        size={memory.is_mobile ? 'small' : 'middle'}
                                    />
                                </Tabs.TabPane>
                                <Tabs.TabPane key="win" tab="赢单审批流程" >
                                    <Table
                                        columns={this.checkColumns}
                                        dataSource={this.state.winData}
                                        pagination={false}
                                        expandRowByClick={true}
                                        scroll={{ x: 'max-content' }} // 加上这条  横向滚动  支持此属性的浏览器内容就不会换行了
                                        size={memory.is_mobile ? 'small' : 'middle'}
                                    />
                                </Tabs.TabPane>
                            </Tabs>
                            
                        </Card>
                    </Tabs.TabPane>
                </Tabs>
                <CollectionCreateForm
                    visible={this.state.finishOpen}
                    onCreate={(values)=>{this.handleFinishedProject(values)}}
                    onCancel={() => {
                        this.setState({
                            finishOpen: false
                        });
                    }}
                />
            </>
        );
    }
}