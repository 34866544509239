import React from "react";
import {
    ConfigProvider,
    Spin,
    Layout,
    Popover,
    Avatar,
    Button,
    message,
    Card,
    List,
    Row,
    Col,
    Input,
    Menu,
    Divider,
    Tooltip,
} from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import { Link } from "react-router-dom";
import * as dd from 'dingtalk-jsapi';
import isMobile from "ismobilejs";
import moment from 'moment';
import 'moment/locale/zh-cn';
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { coldarkDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import remarkGfm from "remark-gfm";
import InfiniteScroll from 'react-infinite-scroller';
import ReactDOMServer from "react-dom/server";
import copyTextToClipboard from "copy-text-to-clipboard";
import { CopyOutlined, MenuUnfoldOutlined, MenuFoldOutlined, SearchOutlined,PauseCircleOutlined } from '@ant-design/icons';

import "./index.less";
import memory from "../../utils/memory";
import LeftNav from "./components/left-nav/LeftNav";
import { AUTH, AVATAR, CORPID, ROLES, TOOLS_PRODUCT_OBJ, UserInfoContext } from "../../utils/constant";
import { reqSelfInfo, reqSignout, reqDepartmentList, aiApi } from "../../api";
import storage from "../../utils/storage";
import ai from "../../assets/gpt/ai.png"

moment.locale('zh-cn');
const { Header, Content, Footer, Sider } = Layout;
const { reqConversationList, reqConversationAdd, reqConversationMessageList, reqConversationDetail, reqConversationCount, reqLogin } = aiApi;

let renderChildren
const nodesMap=(tag,props,isOutputing)=>{
    //后面的光标处理
    let newProps={}
    try{
        for(let key in props){
            if(key=='node' || key=='children' ) continue
            newProps[key]=props.key
        }
        renderChildren=props?.children
    }catch{
        renderChildren=false
    }
    if(!renderChildren){
        return React.createElement(
            tag,
            {...newProps,
                style:{...newProps.style, whiteSpace: "pre-wrap"}
            },
            ""
        )
    }
    try{
        if(typeof(renderChildren[0])=='string' && tag=='code'){
            renderChildren[0]=renderChildren[0].replace("<div _MLOA_CURSOR_></div>","")
        }
        //console.log(props.children)
        if(props.node.children.at(-2) 
            && 
            props.node.children.at(-2).type=='raw' && 
            props.node.children.at(-2).value.includes("<div _MLOA_CURSOR_>")){
            return React.createElement(
                tag,
                {...newProps,
                    style:{...newProps.style, whiteSpace: "pre-wrap"}},
                    renderChildren,
                isOutputing?<span className="MLOA_CURSOR">|</span>:null
                )
        }
        return React.createElement(
            tag,
            {...newProps,
                style:{...newProps.style, whiteSpace: "pre-wrap"}},
                renderChildren
        )
    }catch{
        return React.createElement(
            tag,
            {...newProps,
                style:{...newProps.style, whiteSpace: "pre-wrap"}
            },
            ""
        )
    }
    
}
let inputTimeout=null
let inputValue=""
export default class GptIndex extends React.Component {
    static contextType = UserInfoContext;

    state = {
        loading: true,
        conversationLoading: false,
        collapsed: isMobile(navigator.userAgent).any ? true : false,
        conversationMessageList: [],
        conversationList: [],
        detail: {},
        conversationCount: {},
        infiniteScrollData: {
            page: 1,
            hasMore: true,
        },
        toolsSelected: ["Chat"],
        pictureSize: "2",
        searchInput: "",
        renderKey:"1",
        navRenderKey:'1',
        signal: new AbortController(),
        isNew:true,
        isOutputing:false,
        inputRef:null,
        inputRenderKey:"none"
    };
    leftNavRef = null;
    id = this.props.match.params.id;
    isManager = memory?.user?.roles?.findIndex(o => o.type === ROLES.MANAGER) > -1;

    userInfoHeader = () => {
        return (
            <div className="pop-header">
                <Avatar size="small" className="pop-avatar" src={memory.user.dd_info.avatar} />
                <span className="pop-name">{memory.user.dd_info.name}</span>
            </div>
        );
    }
    userInfoContent = () => {
        return (
            <div className="pop-content">
                <div className="gpt-pop pop-content-info">
                    <div className="info-item">
                        <label className="info-item-label">总次数：</label>
                        <span className="info-item-name">{`${this.state.conversationCount?.max || 0}次`}</span>
                    </div>
                    <div className="info-item">
                        <label className="info-item-label">剩余次数：</label>
                        <span className="info-item-name">{`${(this.state.conversationCount?.max || 0) - (this.state.conversationCount?.used || 0)}次`}</span>
                    </div>
                </div>
                {
                    dd.env.platform !== 'notInDingTalk' ? null : (
                        <footer className="pop-content-footer">
                            <Button type="link" onClick={this.handleSignout}>退出登录</Button>
                        </footer>
                    )
                }
            </div>
        );
    }

    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };
    handleSignout = () => {
        (async () => {
            const response = await reqSignout();
            if (response) {
                message.success('账号已退出');
                storage.removeStore(AUTH);
                this.props.history.replace('/');
            }
        })();
    }

    toLogin = async (code) => {
        const response = await reqLogin({
            code: code,
        });
        const auth = response.data.token;
        storage.saveStore(AUTH, auth);
        message.success('登录成功');

        // 获取个人信息
        const self = await reqSelfInfo();
        if (self && self.data) {
            memory.user = self.data;
            memory.user.dd_info.avatar = memory.user.dd_info.avatar || AVATAR;
            memory.user.role_type = [];
            this.isManager = memory?.user?.roles?.findIndex(o => o.type === ROLES.MANAGER) > -1
            if (self.data.roles && self.data.roles.length) {
                for (let i = 0; i < self.data.roles.length; i++) {
                    memory.user.role_type.push(self.data.roles[i].type);
                }
            }
            // 获取部门信息
            const dep = await reqDepartmentList();
            if (dep) {
                memory.departments = dep.data;
                for (let i = 0; i < dep.data.length; i++) {
                    memory.department_obj[dep.data[i].id] = dep.data[i].name;
                }
            }
            if (this.id) {
                if (this.id.length < 24) {
                    this.props.history.replace(`/gpt`);
                    this.id = "";
                } else {
                    this.getConversationMessageList({
                        id: this.id,
                        page: 1,
                        node: 9999,
                    });
                    this.getConversationDetail({
                        id: this.id,
                    });
                }
            }
            this.getConversationCount();
            this.getConversationList({
                node: 20,
                page: 1,
            });
            this.setState({
                loading: false
            });
        }
    }

    loadMore = (page) => {
        this.getConversationList({
            page,
            node: 20,
        });
    }

    getConversationList = async (params = {}) => {
        if (!params.page) {
            params.page = this.state.infiniteScrollData.page;
        }
        let response = await reqConversationList(params);
        if (response?.data) {
            const arr = [...(this.leftNavRef?.state?.menuList || [])];
            response.data.msg.data.forEach(value => {
                if (arr.findIndex(o => o.id === value._id) === -1) {
                    let item = {
                        title: value.title,
                        key: `/gpt/history/${value._id}`,
                        id: value._id,
                        icon: value.product === "ChatGPT" ? 'message' : "picture",
                        history: true,
                        create_at: value.create_at,
                        product: value.product,
                    }
                    arr.push(item);
                }
            });
            const pageInfo = response.data.msg.pageInfo;
            const infiniteScrollData = { ...this.state.infiniteScrollData };
            infiniteScrollData.hasMore = pageInfo.current < pageInfo.pages;
            infiniteScrollData.page = pageInfo.current;
            this.setState({
                infiniteScrollData,
            });
            try{
                this.leftNavRef.setState({
                    menuList: arr,
                });
            }catch(e){
                console.error("渲染错误↓")
                console.error(e)
            }
            
        }
    }

    handleSelectTool = (selectParams) => {
        this.setState({
            toolsSelected: selectParams.selectedKeys,
        });
    }

    handlePictureSizeChange = (value) => {
        this.setState({
            pictureSize: value
        })
    }

    handleSearch = async (value) => {
        if (value && !this.state.conversationLoading) {
            this.setState({
                conversationLoading: true,
            });

            //是否创建新会话
            const createNew = this.state.isNew
            let { id } = this;
            if (createNew) {
                console.log("创建新会话")
                const menuList = [...(this.leftNavRef?.state?.menuList || [])];
                if (!id || id.length === 24) {//不是临时会话，增加一个并跳转
                    id = menuList.length + "";
                    menuList.splice(1, 0, {
                        title: value,
                        key: `/gpt/history/${id}`,
                        id,
                        icon: TOOLS_PRODUCT_OBJ[this.state.toolsSelected[0]] === "ChatGPT" ? 'message' : "picture",
                        history: true,
                        create_at: moment(),
                        product: TOOLS_PRODUCT_OBJ[this.state.toolsSelected[0]],
                    });
                    try{
                        this.leftNavRef.setState({
                            menuList,
                        }, () => {
                            this.props.history.push(`/gpt/history/${id}`);
                        });
                    }catch(e){
                        console.error("渲染错误↓")
                        console.error(e)
                    }
                    
                }

                const conversationMessageList = [];//新建两条临时消息
                conversationMessageList.push({
                    key: 0,
                    user_id: "user_temporary",
                    message: { type: "text", data: { text: value } },
                    conversation_id: this.id,
                    temporary: true,
                });
                conversationMessageList.push({
                    key: 1,
                    user_id: TOOLS_PRODUCT_OBJ[this.state.toolsSelected[0]],
                    message: { type: "loading" },
                    conversation_id: this.id,
                    temporary: true,
                });
                this.setState({
                    conversationMessageList,
                });
            } else {
                const conversationMessageList = [...this.state.conversationMessageList];//新建两条临时消息
                conversationMessageList.push({
                    key: 0,
                    user_id: "user_temporary",
                    message: { type: "text", data: { text: value } },
                    conversation_id: this.id,
                    temporary: true,
                });
                conversationMessageList.push({
                    key: 1,
                    user_id: TOOLS_PRODUCT_OBJ[this.state.toolsSelected[0]],
                    message: { type: "loading" },
                    conversation_id: this.id,
                    temporary: true,
                });
                this.setState({
                    conversationMessageList,
                });
                setTimeout(() => {
                    document.body.scrollTop = document.body.scrollHeight;
                    const scrollingElement = document.scrollingElement || document.documentElement;
                    scrollingElement.scrollTop = scrollingElement.scrollHeight;
                }, 100);
            }
            const params = {
                content: value,
                conversation_id: createNew ? undefined : this.state.id,
                product: TOOLS_PRODUCT_OBJ[this.state.toolsSelected[0]],
                stream: true
            }
            let headers = {};
            const auth = storage.getStore(AUTH);
            if (auth) {
                headers = {
                    'Content-Type': 'application/json',
                    authorization: 'Bearer ' + auth
                };
            }
            let that = this;
            let arr = [...that.state.conversationMessageList].filter(o => !o.temporary);//筛出临时的
            let randomKey=Math.random().toString(32).slice(2,10)
            //提问的一项
            let userItem = {
                key: "chat_"+randomKey,
                user_id: "testUser",
                message_id: "chat_"+randomKey,
                message:{
                    data:{
                        text:value
                    }
                },
                conversation_id:params.conversation_id,
            };
            arr.push(userItem);
            //AI输出的一项
            let userItem2 = {
                key: "answer_"+randomKey,
                user_id: "ChatGPT",
                message_id: "answer_"+randomKey,
                message:{
                    type:"loading",
                    data:{
                        text: ""
                    }
                },
                conversation_id:params.conversation_id,
            };
            arr.push(userItem2);
            //插入
            that.setState({
                conversationMessageList: arr,
            })
            //let i="这是一段非常简单的JavaScript代码，可以在控制台（Console）中输出\"hello\"：\n```javascript\n// 简单的JavaScript代码，计算两个数字之和并打印结果\nfunction addNumbers(num1, num2) {\n  var sum = num1 + num2;\n  console.log(\"The sum of the numbers is: \" + sum);\n}\n\n// 调用函数举例\naddNumbers(5, 10);  // 输出: The sum of the numbers is: 15\n```"
            //let i=`hello你好\nabsjdjklajdkl`
            let ind=0
            let showText=""
            let renderKey=Math.random().toString(32).slice(2,10)
            //arr.at(-1).message.type='text'
            let inter/*=setInterval(() => {
                if(ind>=i.length){
                    clearInterval(inter)
                    return
                }
                showText+=i[ind]
                arr.at(-1).message.data.text=showText
                that.setState({
                    conversationMessageList: arr,
                    renderKey:renderKey+ind
                })
                ind++
            }, 200);
            
            return*/
            this.setState({
                isOutputing:true,
                searchInput:"",
                inputRenderKey:Math.random().toString(32).slice(2,10)
            })
            inter=setInterval(()=>{
                document.body.scrollTop = document.body.scrollHeight;
                const scrollingElement = document.scrollingElement || document.documentElement;
                scrollingElement.scrollTop = scrollingElement.scrollHeight;
            },300)
            let newConversationId
            fetch(window.location.origin+"/api/ai/conversation", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    authorization: 'Bearer ' + auth
                },
                signal:this.state.signal.signal,
                body: JSON.stringify(params),
            }).then((response) => {
                const reader = response.body.getReader();
                const decoder = new TextDecoder('utf-8');
                function processStreamResult(result2) {
                    let decodeOutcome=decoder.decode(result2.value, { stream: !result2.done }).replace(/\n/g,'')
                    const chunk = decodeOutcome.split("data:");
                    let parsedItem,gptItem,userItem
                    for(let item of chunk){
                        if(item.length==0)continue
                        try{
                            parsedItem=JSON.parse(item)
                            gptItem=parsedItem[0]
                            userItem=parsedItem[1]
                            //修改最后两项的内容
                            if(createNew)newConversationId=userItem.conversation_id
                            arr.at(-2).user_id=userItem.user_id
                            arr.at(-2).conversation_id=userItem.conversation_id
                            arr.at(-1).message=gptItem.message
                            arr.at(-1).user_id=gptItem.user_id
                            arr.at(-1).conversation_id=gptItem.conversation_id
                            that.setState({
                                conversationMessageList: arr,
                                renderKey:renderKey+ind//强制渲染
                            })
                            
                        }catch{}
                        ind++
                    }
                    if (!result2.done) {
                        return reader.read().then(processStreamResult);
                    }else{
                        if(createNew){
                            const newMenuList = [...(that.leftNavRef?.state?.menuList || [])];
                            newMenuList[1].id=newConversationId
                            newMenuList[1].key=`/gpt/history/${newConversationId}`
                            try{
                                that.leftNavRef.updateState(newMenuList,()=>{
                                    that.props.history.push(`/gpt/history/${newConversationId}`);
                                })
                            }catch(e){
                                console.error("渲染错误↓")
                                console.error(e)
                            }
                            
                        }
                        that.setState({
                            isOutputing:false,
                            conversationLoading: false,
                            id:createNew?newConversationId:that.id,
                            isNew:false
                        })
                        clearInterval(inter)
                    }
                    return
                }
                return reader.read().then(processStreamResult);
            })
                .catch(error => {
                    console.error('Error:', error);
                    that.setState({
                        isOutputing:false,
                        conversationLoading: false,
                        id:createNew?newConversationId:that.id,
                        isNew:false
                    })
                    clearInterval(inter)
                });
            return
        }else{
            console.log("here")
        }
    }
    //中断
    interrupt=()=>{
        console.error("interrupt")
        if(this.state.isOutputing){
            this.state.signal.abort()
            this.setState({
                signal:new AbortController()
            })
        }
            
    }
    handleSearchInputChange = (e) => {
        //输入防抖
        clearTimeout(inputTimeout)
        inputTimeout=setTimeout(()=>{
            this.setState({
                searchInput: e.target.value,
            });
        },200)
    }

    handleSearchIconClick = () => {
        this.handleSearch(this.state.searchInput);
    }

    getConversationMessageList = async (params = {}) => {
        this.setState({
            conversationLoading: true,
        })
        let response = await reqConversationMessageList(params);
        if (response?.data) {
            let arr = [];
            response.data.msg.data.reverse().forEach((value, index) => {
                let item = {
                    key: value._id,
                    user_id: value.user_id,
                    message_id: value.message_id,
                    message: value.message,
                    conversation_id: value.conversation_id,
                }
                arr.push(item);
            });
            try{
                this.setState({
                    conversationMessageList: arr,
                    conversationLoading: false,
                })
            }catch(e){
                console.error("渲染错误↓")
                console.error(e)
            }
        }
    }

    getConversationDetail = async (params) => {
        
        let response = await reqConversationDetail(params);
        if (response?.data) {
            if(!params.id){
                message.error("对话ID缺失")
                return
            }
            
            const itemIndex = (this.leftNavRef?.state?.menuList || [])?.findIndex(o => o.key === `/gpt/history/` + params.id);
            let item = {
                title: response.data.msg.title,
                key: `/gpt/history/${response.data.msg._id}`,
                id: response.data.msg._id,
                icon: response.data.msg.product === "ChatGPT" ? 'message' : "picture",
                history: true,
                create_at: response.data.msg.create_at,
                product: response.data.msg.product,
            }
            this.setState({
                detail: item,
                isNew:false,
                id:response.data.msg._id
            });
            if (itemIndex > -1) {
                const arr = [...(this.leftNavRef?.state?.menuList || [])];
                arr[itemIndex] = item;
                try{
                    this.leftNavRef.setState({
                        menuList: arr,
                    })
                }catch(e){
                    console.error("渲染错误↓")
                    console.error(e)
                }
                
            }
        }
    }

    componentWillReceiveProps(newProps) {
        if (newProps.match.params.id !== this.id) {
            if (newProps.match.params.id) {
                const item = (this.leftNavRef?.state?.menuList || []).find(o => o.key === `/gpt/history/` + newProps.match.params.id);
                if (item) {
                    this.setState({
                        detail: item,
                    });
                }
                this.id = newProps.match.params.id;
                if (this.id.length === 24) {
                    this.getConversationDetail({
                        id: this.id,
                    });
                    this.getConversationMessageList({
                        id: this.id,
                        page: 1,
                        node: 9999,
                    });
                }
            } else {
                this.id = newProps.match.params.id;
                this.getConversationCount();
                this.setState({
                    detail: {},
                    conversationMessageList: [],
                });
            }
        }
    }

    getConversationCount = async () => {
        const response = await reqConversationCount();
        if (response?.data) {
            this.setState({
                conversationCount: response.data.msg,
            });
        }
    }

    handleCopy = (e, text) => {
        if (text) {
            const html = ReactDOMServer.renderToStaticMarkup(<ReactMarkdown children={text} />);
            function getText(html) {
                var divContainer = document.createElement("div");
                divContainer.innerHTML = html;
                return divContainer.textContent || divContainer.innerText || "";
            }
            let txt = getText(html);
            txt = txt.replace(/\n\n/g, "\n");
            if (copyTextToClipboard(txt)) {
                message.success("复制成功");
            } else {
                message.error("复制失败，请检查浏览器权限");
            }
        } else {
            message.error("无文本可复制");
        }
    }

    componentDidMount = () => {
        const that = this;
        if (dd.env.platform !== 'notInDingTalk') {
            dd.ready(function () {
                dd.runtime.permission.requestAuthCode({
                    corpId: CORPID, // 企业id
                    onSuccess: function (info) {
                        const code = info.code; // 通过该免登授权码可以获取用户身份
                        that.toLogin(code);
                    },
                    onFail: function (err) {
                        message.error('requestAuthCode error: ' + JSON.stringify(err));
                    }
                });
            });
            dd.error(function (error) {
                message.error('dd error: ' + JSON.stringify(error));
            });
        } else {
            if (this.id) {
                if (this.id.length < 24) {
                    this.props.history.replace(`/gpt`);
                    this.id = "";
                } else {
                    this.getConversationMessageList({
                        id: this.id,
                        page: 1,
                        node: 9999,
                    });
                    this.getConversationDetail({
                        id: this.id,
                    });
                }
            }
            this.getConversationCount();
            this.getConversationList({
                node: 20,
                page: 1,
            });
            this.setState({
                loading: false
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (memory.is_mobile) {
            if (this.props.location.pathname !== prevProps.location.pathname) {
                this.setState({
                    collapsed: true
                });
            }
        }
    }

    render() {
        return (
            <Layout className="crm">
                {
                    this.state.loading ? (
                        <div className="loading-container">
                            <Spin size="large" />
                        </div>
                    ) : (
                        <ConfigProvider locale={zhCN}>
                            <Sider
                                breakpoint="lg"
                                collapsedWidth={memory.is_mobile ? 0 : 80}
                                width={240}
                                trigger={null}
                                collapsible
                                collapsed={this.state.collapsed}
                                style={{
                                    overflow: 'auto',
                                    height: '100%',
                                    position: 'fixed',
                                    left: 0,
                                    zIndex: 99,
                                }}
                            >
                                <div className="menu-flex">
                                    <div className="infinite-scroll">
                                        <InfiniteScroll
                                            initialLoad={false}
                                            pageStart={1}
                                            loadMore={this.loadMore}
                                            hasMore={this.state.infiniteScrollData.hasMore}
                                            useWindow={false}

                                        >
                                            <LeftNav 
                                            key={this.state.navRenderKey}
                                            collapsed={this.state.collapsed} wrappedComponentRef={ref => this.leftNavRef = ref} setState={(params) =>{
                                                this.setState(params)
                                                console.log("set parems",params)
                                            } } 
                                            interrupt={()=>{this.interrupt()}}
                                            addCallback={()=>{this.setState({
                                                isNew:true
                                            })}}
                                            getConversationList={this.getConversationList} toolsSelected={this.state.toolsSelected} />
                                        </InfiniteScroll>
                                    </div>
                                    <div>
                                        <Divider style={{ margin: 0 }} />
                                        <Menu
                                            theme="dark"
                                            mode="inline"
                                            selectedKeys={this.state.toolsSelected}
                                            onSelect={this.handleSelectTool}
                                        >
                                            <Menu.Item className="menu-item" key={"Chat"}>
                                                <div>
                                                    <span>Chat</span>
                                                </div>
                                            </Menu.Item>
                                        </Menu>
                                    </div>
                                </div>
                            </Sider>

                            <Layout
                                style={{
                                    marginLeft: memory.is_mobile ? 0 : (this.state.collapsed ? 80 : 240)
                                }}
                            >
                                <Header
                                    className="crm-header"
                                    style={{
                                        position: 'fixed',
                                        zIndex: 97,
                                        left: 0,
                                        right: 0,
                                        marginLeft: memory.is_mobile ? 0 : (this.state.collapsed ? 80 : 240)
                                    }}
                                >
                                    {
                                        this.state.collapsed ? <MenuUnfoldOutlined className="crm-trigger" onClick={this.toggle} /> : <MenuFoldOutlined className="crm-trigger" onClick={this.toggle} />
                                    }
                                    <div className="user">
                                        {
                                            this.isManager ?
                                                <Link to="/gpt-admin"><Button type="primary" size='small' style={{ marginRight: 16 }}>使用量统计</Button></Link>
                                                :
                                                null
                                        }
                                        <Popover className="popover" content={this.userInfoContent()} title={this.userInfoHeader()} placement="bottomRight">
                                            <Avatar className="avatar" src={memory.user.dd_info.avatar} />
                                        </Popover>
                                    </div>
                                </Header>

                                <Content className="gpt-content" style={{  height: "100%" }}>
                                    <Card
                                        title={this.state.detail?.title}
                                        style={{ height: "100%" }}
                                        className="answer-container"
                                    >
                                        {
                                            this.id ?
                                                <Row type="flex" justify="center" style={{ marginBottom: 140 }}>
                                                    <Col xs={24} sm={24} md={20} lg={16} xl={12}>
                                                        <List
                                                            style={{
                                                                outline:'none'
                                                            }}
                                                            // loading={this.state.conversationLoading}
                                                            dataSource={this.state.conversationMessageList}
                                                            renderItem={(item) => (
                                                                <List.Item>
                                                                    <List.Item.Meta
                                                                        className="conversation-item"
                                                                        avatar={
                                                                            memory.is_mobile ?
                                                                                null
                                                                                :
                                                                                (
                                                                                    item.user_id === "ChatGPT" || item.user_id === "DALLE" ?
                                                                                        <Avatar className="avatar" src={ai} />
                                                                                        :
                                                                                        <Avatar className="avatar" src={memory.user.dd_info.avatar} />
                                                                                )
                                                                        }
                                                                        description={
                                                                            <div style={{ color: "rgba(0,0,0,0.65)", marginTop: 4, position: "relative" }}>
                                                                                {
                                                                                    memory.is_mobile ?
                                                                                        <div style={{ marginBottom: 8 }}>
                                                                                            {
                                                                                                item.user_id === "ChatGPT" || item.user_id === "DALLE" ?
                                                                                                    <Avatar className="avatar" src={ai} />
                                                                                                    :
                                                                                                    <Avatar className="avatar" src={memory.user.dd_info.avatar} />
                                                                                            }
                                                                                        </div>
                                                                                        :
                                                                                        null
                                                                                }
                                                                                {
                                                                                    item.message.type === "text" ?
                                                                                        <>
                                                                                            <ReactMarkdown
                                                                                                key={this.state.renderKey}
                                                                                                className={this.state.isOutputing?"react-markdown":"react-markdown-finish"}
                                                                                                skipHtml={true}
                                                                                                components={{
                                                                                                    code:({ node, inline, className, children, ...props })=> {

                                                                                                        const match = /language-(\w+)/.exec(className || '')
                                                                                                        return !inline && match ? (
                                                                                                            <SyntaxHighlighter
                                                                                                                children={String(children).replace(/\n$/, '').replace(`<div _MLOA_CURSOR_></div>`,"")}
                                                                                                                style={{...coldarkDark,clear:'both'}}
                                                                                                                language={match[1]}
                                                                                                                PreTag="div"
                                                                                                                {...props}
                                                                                                            >

                                                                                                            </SyntaxHighlighter>
                                                                                                        ) : (
                                                                                                            nodesMap("code",{ node, inline, className, children, ...props },this.state.isOutputing)
                                                                                                            /*<code className={className} {...props}>
                                                                                                                {children}
                                                                                                            </code>*/
                                                                                                        )
                                                                                                    },
                                                                                                    li:({ node, inline, className, children, ...props })=>{
                                                                                                        return nodesMap("li",{ node, inline, className, children, ...props },this.state.isOutputing)
                                                                                                    },
                                                                                                    a:({ node, inline, className, children, ...props })=>{
                                                                                                        return nodesMap("a",{ node, inline, className, children, ...props },this.state.isOutputing)
                                                                                                    }, 
                                                                                                    blockquote:({ node, inline, className, children, ...props })=>{
                                                                                                        return nodesMap("blockquote",{ node, inline, className, children, ...props },this.state.isOutputing)
                                                                                                    }, 
                                                                                                    br:({ node, inline, className, children, ...props })=>{
                                                                                                        return <br/>
                                                                                                    }, 
                                                                                                    em:({ node, inline, className, children, ...props })=>{
                                                                                                        return nodesMap("em",{ node, inline, className, children, ...props },this.state.isOutputing)
                                                                                                    }, 
                                                                                                    h1:({ node, inline, className, children, ...props })=>{
                                                                                                        return nodesMap("h1",{ node, inline, className, children, ...props },this.state.isOutputing)
                                                                                                    }, 
                                                                                                    h2:({ node, inline, className, children, ...props })=>{
                                                                                                        return nodesMap("h2",{ node, inline, className, children, ...props },this.state.isOutputing)
                                                                                                    }, 
                                                                                                    h3:({ node, inline, className, children, ...props })=>{
                                                                                                        return nodesMap("h3",{ node, inline, className, children, ...props },this.state.isOutputing)
                                                                                                    }, 
                                                                                                    h4:({ node, inline, className, children, ...props })=>{
                                                                                                        return nodesMap("h4",{ node, inline, className, children, ...props },this.state.isOutputing)
                                                                                                    }, 
                                                                                                    h5:({ node, inline, className, children, ...props })=>{
                                                                                                        return nodesMap("h5",{ node, inline, className, children, ...props },this.state.isOutputing)
                                                                                                    }, 
                                                                                                    h6:({ node, inline, className, children, ...props })=>{
                                                                                                        return nodesMap("h6",{ node, inline, className, children, ...props },this.state.isOutputing)
                                                                                                    }, 
                                                                                                    hr:({ node, inline, className, children, ...props })=>{
                                                                                                        return <hr/>
                                                                                                    }, 
                                                                                                    img:({ node, inline, className, children, ...props })=>{
                                                                                                        return nodesMap("img",{ node, inline, className, children, ...props },this.state.isOutputing)
                                                                                                    }, 
                                                                                                    ol:({ node, inline, className, children, ...props })=>{
                                                                                                        return nodesMap("ol",{ node, inline, className, children, ...props },this.state.isOutputing)
                                                                                                    }, 
                                                                                                    pre:({ node, inline, className, children, ...props })=>{
                                                                                                        return nodesMap("pre",{ node, inline, className, children, ...props },this.state.isOutputing)
                                                                                                    }, 
                                                                                                    strong:({ node, inline, className, children, ...props })=>{
                                                                                                        return nodesMap("strong",{ node, inline, className, children, ...props },this.state.isOutputing)
                                                                                                    },
                                                                                                    ul:({ node, inline, className, children, ...props })=>{
                                                                                                        return nodesMap("ul",{ node, inline, className, children, ...props },this.state.isOutputing)
                                                                                                    },
                                                                                                    
                                                                                                    p:({ node, inline, className, children, ...props })=>{
                                                                                                        return nodesMap("p",{ node, inline, className, children, ...props },this.state.isOutputing)
                                                                                                    },
                                                                                                    table({ node, inline, className, children, ...props }) {
                                                                                                        return (
                                                                                                            <table className={className} {...props} style={{ ...props.style, borderCollapse: "collapse" }}>
                                                                                                                {children}
                                                                                                            </table>
                                                                                                        )
                                                                                                    },
                                                                                                    th({ node, inline, className, children, ...props }) {
                                                                                                        return (
                                                                                                            <th className={className} style={{ ...props.style, textAlign: "center", padding: "1px 4px", border: "1px solid #cbcbcb" }}>
                                                                                                                {children}
                                                                                                            </th>
                                                                                                        )
                                                                                                    },
                                                                                                    td({ node, inline, className, children, ...props }) {
                                                                                                        return (
                                                                                                            <td className={className} style={{ ...props.style, textAlign: "center", padding: "1px 4px", border: "1px solid #cbcbcb" }}>
                                                                                                                {children}
                                                                                                            </td>
                                                                                                        )
                                                                                                    },
                                                                                                }}
                                                                                                remarkPlugins={[remarkGfm]}
                                                                                            >
                                                                                                {(item.message?.data?.text || "-")+`${this.state.isOutputing?'<div _MLOA_CURSOR_></div>':'<div _MLOA_CURSOR_></div>'}`}
                                                                                            </ReactMarkdown>
                                                                                            
                                                                                            {
                                                                                                item.user_id === "ChatGPT" || item.user_id === "DALLE" ?
                                                                                                    <div className="copy-icon-area">
                                                                                                        <Tooltip
                                                                                                            title="复制回答"
                                                                                                        >
                                                                                                            <CopyOutlined className="copy-icon" onClick={(e) => this.handleCopy(e, item.message?.data?.text)} />
                                                                                                        </Tooltip>
                                                                                                    </div>
                                                                                                    :
                                                                                                    null
                                                                                            }
                                                                                        </>
                                                                                        :
                                                                                        (
                                                                                            item.message.type === "loading" ?
                                                                                                <div className="cursor"></div>
                                                                                                :
                                                                                                <div>{item.message.data.text}</div>
                                                                                                
                                                                                        )
                                                                                        
                                                                                }
                                                                            </div>
                                                                        }
                                                                    ></List.Item.Meta>
                                                                </List.Item>
                                                            )}
                                                        >
                                                        </List>
                                                    </Col>
                                                </Row>
                                                :
                                                <Row type="flex" justify="center" style={{ marginBottom: 140 }}>
                                                    <Col xs={24} sm={24} md={20} lg={16} xl={12}>
                                                        <Spin spinning={this.state.conversationLoading}>
                                                            <div style={{ textAlign: "center" }}>
                                                                <div>
                                                                    <Avatar className="avatar" src={ai} size={48} />
                                                                </div>
                                                                <div style={{ fontSize: 24, fontWeight: "bold", marginTop: 8, marginBottom: 8 }}>
                                                                    {`欢迎使用AI工具库！`}
                                                                </div>
                                                                <div style={{ fontSize: 16, marginBottom: 4 }}>
                                                                    {`您每日可用次数：${this.state.conversationCount?.max || 0}次`}
                                                                </div>
                                                                <div style={{ fontSize: 16, marginBottom: 16 }}>
                                                                    {`您本日剩余次数：${(this.state.conversationCount?.max || 0) - (this.state.conversationCount?.used || 0)}次`}
                                                                </div>
                                                                <div style={{ color: "#ababab", fontSize: 14, paddingTop: 36, maxWidth: 420, margin: "auto" }}>
                                                                    同一次对话中Chat支持上下文联想，能对前面的内容继续回复。但每次对话内容字数有限制，来回对话不宜过多，否则会出现回答不完整的情况。如遇这种情况，请新建对话。
                                                                </div>
                                                            </div>
                                                        </Spin>
                                                    </Col>
                                                </Row>
                                        }
                                    </Card>
                                </Content>
                                <div className="ai-footer">
                                    <Footer 
                                        style={{ textAlign: 'center', position: "fixed", bottom: 0, width: memory.is_mobile ? "100%" : `calc(100% - ${memory.is_mobile ? 0 : (this.state.collapsed ? 80 : 240)}px)`, padding: "0px 17px 12px 17px" }}>
                                        {/*
                                            this.data.isOutputing
                                            ?<div className="interrupt-button" onClick={this.interrupt}>
                                                    <PauseCircleOutlined style={{width:'20%',textAlign:'center',marginLeft:'10px'}}/>
                                                    <div style={{width:'80%'}}>停止输出</div>
                                            </div>
                                            :null*/
                                        }
                                        
                                        <div style={{ backgroundColor: "#ffffff", padding: "0 16px 10px 16px", borderTop: "1px solid #ffeeee" }}>
                                            <div style={{ display: "flex", alignItems: "flex-end" }}>
                                                <Input.TextArea
                                                    className="conversation-input"
                                                    id="prompt"
                                                    key={this.state.inputRenderKey}
                                                    autoSize={{ minRows: 3, maxRows: memory.is_mobile ? 10 : 16 }}
                                                    placeholder={memory.is_mobile ? "请输入问题" : "请输入问题(Ctrl+Enter快速搜索)"}
                                                    size="large"
                                                    onKeyDown={(e) => {
                                                        if (e.ctrlKey && e.key === "Enter") {
                                                            this.handleSearch(e.target.value);
                                                        }
                                                    }}
                                                    defaultValue={this.state.searchInput}
                                                    onChange={this.handleSearchInputChange}
                                                />
                                                {
                                                    this.state.isOutputing
                                                    ?<Spin size="50"></Spin>
                                                    :<Button type="primary" icon={<SearchOutlined />} style={{ marginLeft: 4 }} onClick={this.handleSearchIconClick}></Button>
                                                }
                                                
                                            </div>
                                        </div>
                                        <div style={{ paddingTop: 12 }}>
                                            谋乐网络科技 ©{moment().year()}
                                        </div>
                                    </Footer>
                                </div>
                                <div
                                    className="mask-layout"
                                    style={{
                                        display: memory.is_mobile ? (this.state.collapsed ? 'none' : 'block') : 'none'
                                    }}
                                    onClick={() => {
                                        this.setState({
                                            collapsed: true
                                        });
                                    }}
                                >
                                </div>
                            </Layout>
                        </ConfigProvider>
                    )
                }
            </Layout>
        );
    }
}