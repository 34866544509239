import React from "react";
import { Tag } from 'antd';

export default class StateTag extends React.Component {
    render() {
        let { stage, is_examined, is_history } = this.props
        if (is_examined != null) {
            switch (stage) {
                case 1: return '-';
                case 2: return is_examined === true ? <Tag color="#87d068">合同内容审批已通过</Tag> : <Tag color="#808080">合同内容审批未通过</Tag>;
                case 3: return is_examined === true ? <Tag color="#87d068">合同交付部门审批已通过</Tag> : <Tag color="#808080">合同交付部门审批未通过</Tag>;
                case 4: return is_examined === true ? <Tag color="#87d068">法务审批已通过</Tag> : <Tag color="#808080">法务审批未通过</Tag>;
                case 5: return is_examined === true ? <Tag color="#87d068">合同下载审批已通过</Tag> : <Tag color="#808080">合同下载审批未通过</Tag>;;
                case 6: return <Tag color="#87d068">盖章合同已上传</Tag>;
                case 7: return <Tag color="#87d068">合同审批已完成</Tag>;
            }
        }
        switch (stage) {
            case 1: return <Tag color="#f50">等待合同内容审批</Tag>;
            case 2: return <Tag color="#f50">等待合同交付部门审批</Tag>;
            case 3: return <Tag color="#f50">等待法务审批</Tag>;
            case 4: return <Tag color="#f50">等待合同下载审批</Tag>;
            case 5: return <Tag color="#f50">合同待上传</Tag>;
            case 6: return <Tag color="#f50">待确认</Tag>;
            case 7: return is_history === false ? <Tag color="#f50">待寄出</Tag> : <Tag color="#87d068">合同审批已完成</Tag>
        }
        return <span>-</span>
    }
}